import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';

import flagImage from "../assets/images/3d_Book.png";
import backImage from "../assets/images/Back.png";

import eyeImage from "../assets/images/eye_icon.png";
import { useLocation, useNavigate} from 'react-router-dom';

import googleImage from "../assets/images/google.png";
import fbImage from "../assets/images/facebook.png";
import mailImage from "../assets/images/mail.png";
import Swal from 'sweetalert2'

import http from "../utils/config/http";
import { useUserContext, useUserToggleContext} from "../UserProvider";
import { useDataContext } from '../DataProvider';

const Register = (props) => {

  const {saveUser} = useUserToggleContext();
  const [register, setRegister] = useState({
    nombre:"",
    correo:"",
    password:"",
  });
  
  const user = useUserContext();  
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [password, setpassword] = useState("password");
  const [password2, setPassword2] = useState("password")
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  
  const { saveLocal, local } = useDataContext();
  
  const location = useLocation();
  const path = location.pathname;

  useMemo(() => {
    if((path=="/register" || path=="/edit-profile")){
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/usuario/idiomas.php').then((result) => {          
        console.log(result);        
        setLanguages(result.data)
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
      if(path=="/edit-profile"){
        setDisable(true);
        setRegister(user)
      }
    }
  }, []);

  function registerCall(){
    saveUser({id:1});
    navigator.vibrate(3000);
    navigate("/home");
    // http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/register.php",register
    // ).then((result) => {
    //   console.log(result);
    //   if(result && result.data && result.data[0]){
    //     // localStorage.setItem("user",JSON.stringify(result.data[0]));
    //     saveUser(result.data[0]);
    //     navigate("/home");
    //   }else{
    //     Swal.fire({
    //       title: "Usuario o contraseña erroneos.",
    //       showConfirmButton: true,
    //       icon: "warning"
    //     }).then(()=>{
    //     })
    //   }
    // }).catch((err) =>
    //   console.log(err)
    // ).finally(() =>
    //   console.log()
    // )
  }


  function registerCall(){
    console.log(register.nombre, register.correo, register.password);    
    console.log((register.nombre=="" || register.correo =="" || register.password==""));    
    if(!(register.nombre=="" || register.correo =="" || register.password=="")) {
      if(register.password==passwordConfirm) {
        let ind=0;
        languages.map((it)=>{
          if(it.idioma==language){
            ind=it.codigo;
          }            
        });
        let data={
          ...register,
          fuenteRegistro: "web",
          foto: "",
          idioma: ind
        }
        http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/addUsuario.php",data
        ).then((result) => {
          console.log(result);
          if(result && result.data){
            Swal.fire({
              title: ((local && local["register-46"] && local["register-46"].nombre)?local["register-46"].nombre:""),
              showConfirmButton: true,
              icon: "warning"
            }).then(()=>{
            });
            http.get('https://www.fluxiilanguages.com/apiEnglishWeb/etiquetas/etiquetas.php?idioma='+ind).then((result) => {
              console.log(result);            
              saveLocal(result.data);
            }).catch((err) =>
            console.log(err)
            ).finally(() =>
            console.log()
            )          
            navigate("/login");
          }else{
            Swal.fire({
              title: ((local && local["error-43"] && local["error-43"].nombre)?local["error-43"].nombre:""),
              confirmButtonText: ((local && local["error-44"] && local["error-44"].nombre)?local["error-44"].nombre:""),
              showConfirmButton: true,
              icon: "warning"
            }).then(()=>{
            })
          }
        }).catch((err) =>
          console.log(err)
        ).finally(() =>
          console.log()
        )
      }else{
        Swal.fire({
          title: ((local && local["perfil-58"] && local["perfil-58"].nombre)?local["perfil-58"].nombre:""),
          showConfirmButton: true,
          icon: "warning"
        }).then(()=>{
        });
      }
    }
  }
  
  function actualizar(){
    console.log(register.nombre, register.correo, register.password);    
    console.log((register.nombre=="" || register.correo =="" || register.password==""));    
    // if(!(register.nombre=="" || register.correo =="")) {      
      let ind=0;
      languages.map((it)=>{
        console.log(it);        
        console.log(language);        
        if(it.idioma==language){
          ind=it.codigo;
        }            
      });
      console.log(ind);            
      let data={
        "idUsuario": user.id,    
        "nombre": register.nombre,    
        "correo": register.correo,    
        "idioma": ind
      }
      console.log(data);      
      http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/editPerfil.php",data
      ).then((result) => {
        console.log(result);
        // localStorage.setItem("levels",null);        
        if(result && result.data){
          Swal.fire({
            title: ((local && local["perfil-59"] && local["perfil-59"].nombre)?local["perfil-59"].nombre:""),
            showConfirmButton: true,
            icon: "success"
          }).then(()=>{
          });                
          saveUser({
            ...user,
            nombre: data.nombre,
            idioma: data.idioma
          });          
          localStorage.setItem("language",language);
          http.get('https://www.fluxiilanguages.com/apiEnglishWeb/etiquetas/etiquetas.php?idioma='+ind).then((result) => {
            console.log(result);            
            saveLocal(result.data);
          }).catch((err) =>
            console.log(err)
          ).finally(() =>
            console.log()
          )          
          navigate("/profile");
        }else{
          Swal.fire({
            title: ((local && local["error-43"] && local["error-43"].nombre)?local["error-43"].nombre:""),
            confirmButtonText: ((local && local["error-44"] && local["error-44"].nombre)?local["error-44"].nombre:""),
            showConfirmButton: true,
            icon: "warning"
          }).then(()=>{
          })
        }
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )     
    // }
  }

  function actualizarPassword(){
    console.log(register.password,passwordConfirm);        
    console.log(register.password==passwordConfirm);        
    if(!(register.password=="" || passwordConfirm=="")){
      if(register.password==passwordConfirm) {            
        let data={
          "idUsuario": user.id, 
          "password": passwordConfirm
        }
        http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/editContra.php",data
        ).then((result) => {
          console.log(result);
          if(result && result.data){
            Swal.fire({
              title: ((local && local["perfil-58"] && local["perfil-58"].nombre)?local["perfil-58"].nombre:""),
              showConfirmButton: true,
              icon: "success"
            }).then(()=>{
            });          
            navigate("/profile");
          }else{
            Swal.fire({
              title: ((local && local["error-43"] && local["error-43"].nombre)?local["error-43"].nombre:""),
              confirmButtonText: ((local && local["error-44"] && local["error-44"].nombre)?local["error-44"].nombre:""),
              showConfirmButton: true,
              icon: "warning"
            }).then(()=>{
            })
          }
        }).catch((err) =>
          console.log(err)
        ).finally(() =>
          console.log()
        )
      }else{
        Swal.fire({
          title: ((local && local["perfil-56"] && local["perfil-56"].nombre)?local["perfil-56"].nombre:""),
          showConfirmButton: true,
          icon: "warning"
        }).then(()=>{
        });
      }
    }
  }

  function changeIdioma(e){
    console.log(e.target);
    console.log(e.target.value);
    console.log(e.target.value.idioma);    
    setLanguage(e.target.value);
  }

  function back(e){
    window.history.back();  
  }

  function changeInput(e){
    setRegister({
      ...register,
      [e.target.name]: e.target.value
    });
  }
  
  function changeInput2(e){
    setPasswordConfirm(      
      e.target.value
    );
  }


  return (
    <section className="register">
      <header>
        {(path=="/register") && <figure>
          <img src={flagImage}/>
        </figure>}
        {!(path=="/register") && 
        <figure onClick={back} className="back">
          <img src={backImage}/>
        </figure>}
        {path=="/register" && <h2>{(local && local["register-49"] && local["register-49"].nombre)?local["register-49"].nombre:""}</h2>}
        {path=="/edit-profile" && <h2>{(local && local["perfil-54"] && local["perfil-54"].nombre)?local["perfil-54"].nombre:""}</h2>}
        {path=="/edit-password" && <h2>{(local && local["perfil-57"] && local["perfil-57"].nombre)?local["perfil-57"].nombre:""}</h2>}
      </header>
      <article>
        {(path=="/register" || path=="/edit-profile") &&
        <>
          <div>
            <input type="text" value={register.nombre} placeholder={(local && local["register-50"] && local["register-50"].nombre)?local["register-50"].nombre:""} name="nombre" onChange={changeInput}/>
          </div>
          <div>
            <input type="text" value={register.correo} placeholder={(local && local["inicio_sesion-2"] && local["inicio_sesion-2"].nombre)?local["inicio_sesion-2"].nombre:""} name="correo" onChange={changeInput}/>
          </div>
        </>
        }    

        {(path=="/register" || path=="/edit-password") &&
        <>  
          <div>
            <input type={password}  value={register.password} placeholder={(local && local["inicio_sesion-3"] && local["inicio_sesion-3"].nombre)?local["inicio_sesion-3"].nombre:""} name="password" onChange={changeInput}/>
            <figure onClick={()=>{setpassword((password=="text")?"password":"text")}}>
              <img src={eyeImage}/>
            </figure>
          </div>          
          <div>
            <input type={password2}  value={passwordConfirm} placeholder={(local && local["inicio_sesion-65"] && local["inicio_sesion-65"].nombre)?local["inicio_sesion-65"].nombre:""} name="password2" onChange={changeInput2}/>
            <figure onClick={()=>{setPassword2((password2=="text")?"password":"text")}}>
              <img src={eyeImage}/>
            </figure>
          </div>
        </>
        }
        {(path=="/register" || path=="/edit-profile") &&
          <div>
            <select value={language} onChange={changeIdioma} name="" id="">            
              <option value="" selected disabled>{(local && local["inicio_sesion-68"] && local["inicio_sesion-68"].nombre)?local["inicio_sesion-68"].nombre:""}</option>
              { 
                languages.map((it)=>{
                  return(
                    <option value={it.idioma}>{it.paisPrincipal}</option>
                  )
                })
              }
            </select>
          </div>
        }
      </article>
      {path=="/register" && <footer>
        <div class="btn" onClick={registerCall}>Registrar</div>
        {false && 
          <>
            <article>
              <p>{((local && local["register-47"] && local["register-47"].nombre)?local["register-47"].nombre:"")}</p>
            </article>
            <section>
              <figure onClick={()=>{registerCall()}}>
                <img src={googleImage}/>
              </figure>
              <figure>
                <img src={fbImage}/>
              </figure>
              <figure>
                <img src={mailImage}/>
              </figure>
            </section>
          </>
          }
          <p onClick={()=>{navigate("/login");}}>{(local && local["inicio_sesion-1"] && local["inicio_sesion-1"].nombre)?local["inicio_sesion-1"].nombre:""}</p>
        </footer>
      }     
      {path=="/edit-profile" && <footer>
        <div class="btn" onClick={actualizar}>{(local && local["perfil-55"] && local["perfil-55"].nombre)?local["perfil-55"].nombre:""}</div>        
      </footer>}
      {path=="/edit-password" && <footer>
        <div class="btn" onClick={actualizarPassword}>{(local && local["perfil-55"] && local["perfil-55"].nombre)?local["perfil-55"].nombre:""}</div>        
      </footer>}
    </section>
);
}

export default Register;
