import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate} from 'react-router-dom';

import http from "../utils/config/http";

import awardImage from "../assets/images/medal.png";
import { useUserContext, useUserToggleContext} from "../UserProvider";
import { useDataContext } from '../DataProvider';


const Status = (props) => {

  // const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({});
  const {logout} = useUserToggleContext();
  const navigate = useNavigate();
  const [idCategoria, setIdCategoria] = useState(localStorage.getItem("idCategoria"));
  const user = useUserContext();
  const { local } = useDataContext();


  useMemo(() => {
    http.get('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/selectCertificadoIndividual.php?idNivel='+idCategoria+'&idUsuario='+user.id).then((result) => {
      console.log(result);
      if(result && result.data && result.data[0] && result.data[0].score){
        setStatus(result.data[0]);
      }else{
        navigate("/test/"+idCategoria);
      }
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }, []);


  function cerrarSesion(){
    logout();
    navigate("/login");
  }

  function changeInput(e){
    setStatus({
      ...status,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="status">
      <article>
        <h2>Su nivel es:</h2>
        <h3>{status.nivel}</h3>
        <section>
          <figure>
            <img src={awardImage}/>
          </figure>
          <header>
            <h3>{status.nombre}</h3>
            <h4>Usted es mejor que 89% de usuarios!</h4>
          </header>
          <article>
            <div>
              <h5>las monedas obtenidas</h5>
              <p>15340</p>
            </div>
            <div>
              <h5>Ganancias de los stickers</h5>
              <p>5</p>
            </div>
            <div>
              <h5>temas completado</h5>
              <p>25</p>
            </div>
            <div>
              <h5>Las palabras aprendidas</h5>
              <p>381</p>
            </div>
            <div>
              <h5>Certificado</h5>
              <figure>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/></svg>
              </figure>
            </div>
            <footer>
              <div class="btn">
                <p>Compartir</p>
                <figure>
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/></svg>
                </figure>
              </div>
            </footer>
          </article>
        </section>
      </article>
      <footer>
        <div class="btn">
          {(local && local["certificado-23"] && local["certificado-23"].nombre)?local["certificado-23"].nombre:""}
        </div>
        <div class="btn2">
          {(local && local["certificado-24"] && local["certificado-24"].nombre)?local["certificado-24"].nombre:""}          
        </div>
      </footer>
    </section>
);
}

export default Status;
