import React from 'react';
import ReactDOM from 'react-dom';

function Loading({ children, closeCustomModal}) {

  return ReactDOM.createPortal(
    <article className="loading">
      <section>
        <div class="loadingio-spinner-spin-a3vnr2pg3ji"><div class="ldio-xv3gnwmmwqr">
        <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
        </div></div>
        {children}
      </section>
      <div className="loading-bg">
      </div>
    </article>,
    document.getElementById('loading')
  );
}

export { Loading };
