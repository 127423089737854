import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import keySfx from '../../assets/sound/keydown.wav';
import useSound from 'use-sound';
import wrongSfx from '../../assets/sound/wrong.mp3';
import correctSfx from '../../assets/sound/correct.mp3';

const Circle = (props) => {


  const [circle, setCircle] = useState(null);
  const [letters, setLetters] = useState([]);
  const [letters2, setLetters2] = useState(["_","_","_","_","_","_","_","_","_"]);
  const [count, setCount] = useState(0);
  const [lettersCorrect, setLettersCorrect] = useState([]);
  const [countWrong, setCountWrong] = useState(0);

const [keyPlay, { stop }] = useSound(keySfx,
  {
    volume: 1,
  });
const [wrongPlay, { stop: stopWrong }] = useSound(wrongSfx,
    {
      volume: .6,
     });
     const [correctPlay, { sound }] = useSound(correctSfx,
    {
      volume: 1,
      onend: (e) => {
        // console.log(e);
        // console.log(sound);
        // sound.playing(0);
      },
    });
  useMemo(() => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    let letters2=props.data.nombreEn.split("");
    let pos=props.data.significadoEn.toLowerCase().indexOf(props.data.nombreEn.toLowerCase());

    let letters3=[];
    let letters1=[];
    let letters4=[];

    letters2.map((it,i)=>{
      letters3.push("_");
      letters4.push(it);
      letters1.push({
        letter: it,
        check: false,
        correct: true,
        visible: true,
        i
      })
    })
    setLettersCorrect(letters4);

    const shuffeled = letters1.sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    setCountWrong(0);

    setCount(0);
    setLetters2(letters3);
    setLetters(shuffeled);
  }, [props.data]);


  function selectLetter(ind,it){
    if(lettersCorrect[count].toUpperCase()==it.letter.toUpperCase()){
      keyPlay();
      if(count<letters2.length){
        let letters1=JSON.parse(JSON.stringify(letters2));
        letters1[count]= it.letter;
        setCount(count+1);
        setLetters2(letters1);

        letters1=JSON.parse(JSON.stringify(letters));
        letters1[ind].check= !(letters[ind].check);
        // letters1[ind].visible= false;
        setLetters(letters1);
        console.log(count,letters2.length);
        if(count==letters2.length-1){
          correctPlay();
          let res={
            ...props.data,
            correct: (countWrong<2),
          }
          props.response(res);
        }
      }
    }else{
      setCountWrong(countWrong+1);
      wrongPlay();
    }
  }

  function selectLetter2(ind){
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    setLetters(letters1);
  }

  function changeInput(e){
    setCircle({
      ...circle,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="circle">
      <header>
        <article>
          <h4>{props.data.nombreEs}</h4>
        </article>
        <div>
          <h4>
          {
            letters2.map((it,i)=>{
              return(
                <span key={i}>{it}</span>
              )
            })
          }
          </h4>
        </div>
      </header>
      <article>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                done: (it.check && it.correct),
                wrong: (it.check && !it.correct)
              });
              return(
                <>
                  {(!it.check) && <li key={i} className={className} onClick={()=>{selectLetter(i,it)}}>{it.letter}</li>}
                </>
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default Circle;
