import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useNavigate} from 'react-router-dom';

import {Modal} from '../util/modal';
import {Loading} from '../util/loading';

import backImage from "../../assets/images/Back.png";
import menuImage from "../../assets/images/Menu.png";
import Swal from 'sweetalert2'

import Init from "./init";
import Result from "./result";
import Search from "./search";
import Complete from "./complete";
import ImageWord from "./image-word";
import WordImage from "./word-image";
import Circle from "./circle";
import Audio from "./audio";
import Word from "./word";
import Audio2 from "./audio2";
import Meaning from "./meaning";
import WordMeaning from "./word-meaning";
import CompleteWord from "./complete-word";

import useSound from 'use-sound';
import backgroundSfx from '../../assets/sound/background.mp3';
import correctSfx from '../../assets/sound/correct.mp3';
import wrongSfx from '../../assets/sound/wrong.mp3';
import timeSfx from '../../assets/sound/time.mp3';
import winSfx from '../../assets/sound/win-level.mp3';
import loseSfx from '../../assets/sound/lose.mp3';


// import Box from "./box";
// import Level1 from "./level1";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import http from "../../utils/config/http";
import { useUserContext } from "../../UserProvider";
import { useDataContext } from '../../DataProvider';


const Level = (props) => {

  const user = useUserContext();
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const [typeLevelCategory, setTypeLevelCategory] = useState(localStorage.getItem("typeLevelCategory"));
  const [typeLevel, setTypeLevel] = useState();
  const [idCategoria, setIdCategoria] = useState(localStorage.getItem("idCategoria"));
  const [subNivel, setSubNivel] = useState(localStorage.getItem("subNivel"));
  const { local } = useDataContext();

  const [intro, setIntro] = useState(true);
  const [result, setResult] = useState(false);
  // "Selecciona la palabra correcta",
  // "Encuentra la palabra",
  // "Completa la palabra",
  // "Selecciona la imagen con la palabra correcta.",
  // "Completa la palabra correspondiente a la imagen.",
  // "Completa la palabra con las letras.",
  // "Completa la palabra del audio con las letras.",
  // "Seleccione la palabra correcta.",
  // "Seleccione la palabra correcta.",
  // "Arrastra la palabra correcta, al significado.",
  // "Arrastra la palabra faltante."
  const [intros, setIntros] = useState([]);
  const [round, setRound] = useState(0);
  const [rounds, setRounds] = useState([]);
  const [totalPuntos, setTotalPuntos] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [key, setKey] = useState(0);
  const [duration, setDuration] = useState(30);
  const [complete, setComplete] = useState(false);
  const [currentLevel, setCurrentLevel] = useState((localStorage.getItem("currentLevel") && JSON.parse(localStorage.getItem("currentLevel")))?JSON.parse(localStorage.getItem("currentLevel")):{});
  const [pauseApp, setPauseApp] = useState(false);

  const [levelWords, setLevelWords] = useState([[{}],[{}]]);
  const [level, setLevel] = useState(
    {
      count: -1,
      total: levelWords[round].length
      // total: 3
    }
  );

  const [wrong, setWrong] = useState([]);
  const [correct, setCorrect] = useState(0);


  const [backgroundPlay, { stop }] = useSound(backgroundSfx,
    {
      volume: 0.08,
     });

    // const [isPlaying2, setIsPlaying2] = useState(true);
   // const togglePlay = () => {
   //   console.log(isPlaying2);
   //   if (isPlaying2) {
   //     stop();
   //   } else {
   //     backgroundPlay();
   //   }
   //   setIsPlaying(!isPlaying2);
   // }

  useEffect(() => {
    console.log(local);    
    setIntros([
      "",
    ((currentLevel.id>=4)?((local && local["levelavanzado-73"] && local["levelavanzado-73"].nombre)?local["levelavanzado-73"].nombre:""):((local && local["level-32"] && local["level-32"].nombre)?local["level-32"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-74"] && local["levelavanzado-74"].nombre)?local["levelavanzado-74"].nombre:""):((local && local["level-33"] && local["level-33"].nombre)?local["level-33"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-77"] && local["levelavanzado-77"].nombre)?local["levelavanzado-77"].nombre:""):((local && local["level-36"] && local["level-36"].nombre)?local["level-36"].nombre:"")),    
    ((currentLevel.id>=4)?((local && local["levelavanzado-75"] && local["levelavanzado-75"].nombre)?local["levelavanzado-75"].nombre:""):((local && local["level-34"] && local["level-34"].nombre)?local["level-34"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-76"] && local["levelavanzado-76"].nombre)?local["levelavanzado-76"].nombre:""):((local && local["level-35"] && local["level-35"].nombre)?local["level-35"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-77"] && local["levelavanzado-77"].nombre)?local["levelavanzado-77"].nombre:""):((local && local["level-36"] && local["level-36"].nombre)?local["level-36"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-78"] && local["levelavanzado-78"].nombre)?local["levelavanzado-78"].nombre:""):((local && local["level-37"] && local["level-37"].nombre)?local["level-37"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-79"] && local["levelavanzado-79"].nombre)?local["levelavanzado-79"].nombre:""):((local && local["level-38"] && local["level-38"].nombre)?local["level-38"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-79"] && local["levelavanzado-79"].nombre)?local["levelavanzado-79"].nombre:""):((local && local["level-38"] && local["level-38"].nombre)?local["level-38"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-80"] && local["levelavanzado-80"].nombre)?local["levelavanzado-80"].nombre:""):((local && local["level-39"] && local["level-39"].nombre)?local["level-39"].nombre:"")),
    ((currentLevel.id>=4)?((local && local["levelavanzado-81"] && local["levelavanzado-81"].nombre)?local["levelavanzado-81"].nombre:""):((local && local["level-40"] && local["level-40"].nombre)?local["level-40"].nombre:"")),    
    ]);

    document.addEventListener("pause", () => {   
      console.log('pause');  
      setPauseApp(true);      
      console.log('pause2');      
    }, false);
    document.addEventListener("resume", () => {
      setPauseApp(false);
      console.log('resume');
      console.log(intro, result);      
      console.log(!(intro || result));      
    }, false);    
  }, []);
  const [correctPlay, { sound }] = useSound(correctSfx,
    {
      volume: 1,
      onend: (e) => {
        // console.log(e);
        // console.log(sound);
        // sound.playing(0);
      },
    });

  useEffect(() => {
    if(pauseApp){
      stop();
      setIsPlaying(false);   
    }else{
      if(!(intro || result)){
        setIsPlaying(true);          
        backgroundPlay();
      }
    }
  }, [pauseApp]);
  const [wrongPlay, { stop: stopWrong }] = useSound(wrongSfx,
    {
      volume: 1,
     });
  const [timePlay, { stop: timeStop }] = useSound(timeSfx,
    {
      volume: 1,
     });
  const [winPlay, { stop: winStop }] = useSound(winSfx,
    {
      volume: 1,
     });
  const [losePlay, { stop: loseStop }] = useSound(loseSfx,
    {
      volume: 1,
     });

  useEffect(() => {
    return ()=>{
    }
  }, []);


  useMemo(() => {
    setDuration(30);
    if(idCategoria<4){
      if(typeLevelCategory==1){
        setTypeLevel(7)
        setDuration(60);        
      }
      if(typeLevelCategory==2){
        setTypeLevel(8)
        setDuration(80)
      }
      if(typeLevelCategory==3){
        setTypeLevel(5);
        setDuration(20);
      }
      if(typeLevelCategory==4){
        setTypeLevel(3)
        setDuration(20);
      }
    }else{
      if(typeLevelCategory==1){
        setTypeLevel(7)
        setDuration(20);
      }
      if(typeLevelCategory==2){
        setTypeLevel(9)
        setDuration(80)
      }
      if(typeLevelCategory==3){
        setTypeLevel(6);
        setDuration(20);
      }
      if(typeLevelCategory==4){
        setTypeLevel(11)
        setDuration(80);
      }

    }

  }, []);

  useEffect(() => {
    console.log(wrong);
  }, [wrong]);

  useEffect(() => {
    if(complete){
      let totalPuntos2= totalPuntos+(correct*5);
      if(totalPuntos2>=70){
        winPlay();
      }else{
        losePlay();
      }
      http.post("https://www.fluxiilanguages.com/apiEnglishWeb/nivel/editResultado.php",{
          "idUsuario": user.id,
          "idSubNivel": subNivel,
          idCategoria: typeLevelCategory,
          totalPuntos: totalPuntos2,
          "estado": (totalPuntos2)>=70?1:0
      }
      ).then((result) => {
        console.log(result);        
        let subNiveles2=JSON.parse(localStorage.getItem("subNiveles"));
        console.log(subNiveles2);
        console.log(idCategoria);
        console.log(subNiveles2[idCategoria-1]);        
        if(subNiveles2[idCategoria-1]){
          subNiveles2[idCategoria-1]=null;
          localStorage.setItem("subNiveles",JSON.stringify(subNiveles2));  
        }
        localStorage.setItem("categorias",null);
        localStorage.setItem("levels",null);
        if(result){
        }
      }).catch((err) =>{  
        let subNiveles2=JSON.parse(localStorage.getItem("subNiveles"));
        if(subNiveles2[idCategoria-1]){
          subNiveles2[idCategoria-1]=null;
          localStorage.setItem("subNiveles",JSON.stringify(subNiveles2));  
        }
        localStorage.setItem("categorias",null);
        localStorage.setItem("levels",null);
        console.log(err)
      }
        ).finally(() =>{
          let subNiveles2=JSON.parse(localStorage.getItem("subNiveles"));
          if(subNiveles2[idCategoria-1]){
            subNiveles2[idCategoria-1]=null;
            localStorage.setItem("subNiveles",JSON.stringify(subNiveles2));  
          }
          localStorage.setItem("categorias",null);
          localStorage.setItem("levels",null);
        }
      )
    }
  }, [complete]);

  useEffect(() => {
    let wrongWords=[];
    let myArray=levelWords[round].filter((it,i)=>{return i!=level.count})
    console.log(myArray);
    let getMeRandomElements = function(sourceArray, neededElements) {
      let result = [];
      for (let i = 0; i < neededElements; i++) {
      let index = Math.floor(Math.random() * sourceArray.length);
          result.push(sourceArray[index]);
          sourceArray.splice(index, 1);
      }
      console.log(result);
      return result;
    }
    setWrong(getMeRandomElements(myArray,8));
  }, [level.count]);

  function response(res){
    console.log(res);
    setIsPlaying(false);
    let timer=0;
    if(typeLevel==2 || typeLevel == 3 || typeLevel == 4 || typeLevel == 5 || typeLevel == 6 || typeLevel == 7){
      timer=0;
    }
    setTimeout(()=>{
      setRounds([
        ...rounds,
        res.correct
      ])
      if(res.correct){
        setCorrect(correct+1);
        if(!(typeLevel == 3 || typeLevel == 4 || typeLevel == 5  || typeLevel == 6 || typeLevel == 7)){
          correctPlay();
        }
        if(typeLevel!=4){
          // let snd= new Audio("../../assets/sonidos/win.mp3");
          //
          // (snd && snd.play())?snd.play():"";
          // Swal.fire({
          //   title: "Correct",
          //   showConfirmButton: false,
          //   allowOutsideClick: false,
          //   icon: "success",
          //   timer: 1500,
          // }).then(()=>{
          //   setIsPlaying(true);
          //   next();
          // })
          setIsPlaying(true);
          setTimeout(()=>{
            next();
          },400);
        }else{
          setIsPlaying(true);
          next();
        }
      }else{
        navigator.vibrate(1000);
        if(!(typeLevel == 3 || typeLevel == 4 || typeLevel == 5  || typeLevel == 6 || typeLevel == 7)){
          wrongPlay();
        }
        if(typeLevel!=4){
          // Swal.fire({
          //   title: "Wrong",
          //   showConfirmButton: false,
          //   allowOutsideClick: false,
          //   icon: "error",
          //   timer: 1500,
          // }).then(()=>{
          //   setIsPlaying(true);
          //   next();
          // })
          setIsPlaying(true);
          setTimeout(()=>{
            next();
          },400);
        }else{
          setIsPlaying(true);
          next();
        }
      }
    },timer)
  }

  function next(){
    console.log((level.count+1>=level.total));
    console.log(level.count,level.total);
    
    if(level.count+1>=level.total){
      completeRound();
      setIsPlaying(false);
      setKey(prevKey => prevKey + 1);
    }else{
      console.log(1234);
      if(typeLevel==2 || typeLevel == 3 || typeLevel == 5 || typeLevel == 6 || typeLevel == 7){
        setKey(prevKey => prevKey + 1);
      }
      setLevel({
        ...level,
        count: level.count+1
      });
    }
  }

  function prev(){
    setLevel({
      ...level,
      amount: level.amount--
    })
  }

  function finish(totalPuntos){

  }

  function back(e){
    setIsPlaying(false);
    Swal.fire({
      title: ((local && local["modal_salir-27"] && local["modal_salir-27"].nombre)?local["modal_salir-27"].nombre:""),
      html: "<div class='ico-gato'><figure></figure></div>", 
      showConfirmButton: true,
      customClass: {
        icon: "no-before-icon",
      },
      width: "85%",
      showCancelButton: true,
      confirmButtonText: ((local && local["modal_salir-42"] && local["modal_salir-42"].nombre)?local["modal_salir-42"].nombre:""),
      cancelButtonText: ((local && local["modal_salir-28"] && local["modal_salir-28"].nombre)?local["modal_salir-28"].nombre:""),
      allowOutsideClick: false,
      icon: "info"
    }).then((res)=>{
      if(res && res.isConfirmed){
        setIsPlaying(true);
      }
      if(res && res.isDismissed){
         stop();
        navigate("/main");//
      }
    })
  }

  function go(){
    console.log(round);    
    if(round>0){
      backgroundPlay();
      setIntro(false);
      setLevel({
        ...level,
        count: level.count+1
      })
      setIsPlaying(true);
    }else{

      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/nivel/selectPalabras.php?subNivel='+subNivel+"&idUsuario="+user.id).then((result) => {        
        let data=[];      
        
        result.data.map((it)=>{
          it.nombreEs= it.nombreEs.charAt(0).toUpperCase() + it.nombreEs.slice(1);
        })
     
        const shuffeled = result.data.sort(() => {
          const randomTrueOrFalse = Math.random() > 0.5;
          return randomTrueOrFalse ? 1 : -1
        });
        function sliceIntoChunks(arr, chunkSize) {
          const res = [];
          for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
          }
          return res;
        }
        if(idCategoria==3 || idCategoria==4){        
          const shuffeled1 = shuffeled.sort((a, b) => parseFloat(a.nombreEn.length) - parseFloat(b.nombreEn.length));                
          
          let newArr=sliceIntoChunks(shuffeled1,10);        
          
          const arr1 = newArr[0].sort(() => {
            const randomTrueOrFalse = Math.random() > 0.5;
            return randomTrueOrFalse ? 1 : -1
          });
          const arr2 = newArr[1].sort(() => {
            const randomTrueOrFalse = Math.random() > 0.5;
            return randomTrueOrFalse ? 1 : -1
          });
  
          // localStorage.setItem("level",JSON.stringify([arr2,arr1]));
          if(idCategoria==3){        
            localStorage.setItem("level",JSON.stringify([arr2,arr1]));        
            setLevelWords([arr2,arr1]);
          }
          if(idCategoria==4){        
            localStorage.setItem("level",JSON.stringify([arr1,arr2]));        
            setLevelWords([arr2,arr1]);
          }
        }else{
          let newArray=sliceIntoChunks(shuffeled,10);
          localStorage.setItem("level",JSON.stringify(newArray));
          setLevelWords(newArray);
        }
        backgroundPlay();
        setIntro(false);
        setLevel({
          total: 10,
          count: 0
        })
        setIsPlaying(true);      
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )     
    }
    // setIsPlaying(false);
  }


  function reset(){
    setResult(false);
    setRound(0);
    setCorrect(0);
    setTotalPuntos(correct*5);
    setIntro(true);
    setRounds([]);
    setLevel({
      count: -1,
      total: levelWords[round].length
      // total: 3
    })
  }

  function completeRound(){
    setResult(true);
    if(round==1){
      setComplete(true);
      // finish(totalPuntos+(correct*5));
    }
  }

  function completeResult(){

    if(round==1){
      stop();
      navigate("/main");
    }else{
      if(idCategoria<4){

        if(typeLevelCategory==1){
          setTypeLevel(1);
          setDuration(80);
        }
        if(typeLevelCategory==2){
          setTypeLevel(4);
          setDuration(60);
        }
        if(typeLevelCategory==3){
          setTypeLevel(2);
          setDuration(50);
        }
        if(typeLevelCategory==4){
          setTypeLevel(5);
          setDuration(20);
        }
      }else{
        if(typeLevelCategory==1){
          setTypeLevel(1);
          setDuration(30);
        }
        if(typeLevelCategory==2){
          setTypeLevel(10);
          setDuration(80);
        }
        if(typeLevelCategory==3){
          setTypeLevel(2);
          setDuration(50);
        }
        if(typeLevelCategory==4){
          setTypeLevel(3);
          setDuration(20);
        }
      }
      setResult(false);
      setRound(round+1);
      setCorrect(0);
      setTotalPuntos(correct*5);
      setIntro(true);
      setRounds([]);
      setLevel({
        count: -1,
        total: levelWords[round].length
        // total: 3
      })
    }
  }

  function completeTime(e){
    if(typeLevel==2 || typeLevel == 3 || typeLevel == 5 || typeLevel == 6 || typeLevel == 7){
      let res={
        ...levelWords[round][level.count],
        correct: false
      }
      response(res);
    }else{
      timePlay();
      Swal.fire({
        title: ((local && local["error-85"] && local["error-85"].nombre)?local["error-85"].nombre:""),
        showConfirmButton: true,
        allowOutsideClick: false,
        icon: "warning"
      }).then(()=>{
        setTotalPuntos(totalPuntos+(correct*5));
        // finish(totalPuntos+(correct*5));
        setComplete(true);
        setTimeout(()=>{
          stop();
          navigate("/main");
        },1000);
      })
    }
  }

  function changeInput(e){
    setLevel({
      ...level,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="level">
      <header>
        <figure onClick={back}>
          <img src={backImage}/>
        </figure>
        {!(intro) && <h5>{level.count+1}  /  {level.total}</h5>}
        {(intro) && <h5>Round {round+1}  /  2</h5>}
        <div>
          {!(intro) && !(result) && <figure>
            <CountdownCircleTimer
              isPlaying={isPlaying}
              key={key}
              duration={duration}
              size= {40}
              strokeWidth= {5}
              colors={['#6ad178', '#F7B801', '#A30000', '#A30000']}
              colorsTime={[7, 5, 2, 0]}
              onComplete= {completeTime}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </figure>}
        </div>
      </header>
      <article>
        {intro && <Init level={{}} text={intros[typeLevel]} typeLevelCategory={typeLevelCategory} go={go}/>}
        {result && <Result level={{}} rounds={rounds} levelWords={levelWords[round]} round={round} completeResult={completeResult} reset={reset} total={correct*5}/>}
        {!intro && !result && level.count>=0 && <>
          {(typeLevel ==1 ) && <Audio data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==2 ) && <Search data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==3 ) && <Complete data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==4 ) && <ImageWord data={levelWords[round]} wrong={wrong} response={response}/>}
          {(typeLevel ==5 ) && <WordImage data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==6 ) && <Circle data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==7 ) && <Audio2 data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==8 ) && <Word data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==9 ) && <Meaning data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==10 ) && <WordMeaning data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
          {(typeLevel ==11 ) && <CompleteWord data={levelWords[round][level.count]} wrong={wrong} response={response}/>}
        </>}
      </article>
      <footer>
      </footer>
    </section>
);
}

export default Level;
