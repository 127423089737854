import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import useSound from 'use-sound';

import wrongSfx from '../../assets/sound/wrong.mp3';
import correctSfx from '../../assets/sound/correct.mp3';

import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const ImageWord = (props) => {

  const [imageWord, setImageWord] = useState(null);
  const [letters, setLetters] = useState([]);
  const [images, setImages] = useState([]);
  const [count, setCount] = useState(4);
  const [responding, setResponding] = useState(false);
  const [countWrong, setCountWrong] = useState(0);

  const [correctPlay, { sound }] = useSound(correctSfx,
    {
      volume: 1,
      onend: (e) => {
        // console.log(e);
        // console.log(sound);
        // sound.playing(0);
      },
    });
const [wrongPlay, { stop: stopWrong }] = useSound(wrongSfx,
    {
      volume: .6,
     });

  useMemo(() => {
    setResponding(false)
    function sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    }
    let letters1=props.data;
    letters1.map((it,i)=>{
      it.image= (it.img)?it.img:"https://dummyimage.com/600x400/000/fff&text="+it.nombreEn;
      it.i= i;
      it.visible= true;
      it.correct= true;
    });
    let words=sliceIntoChunks(props.data,5);
    let wordsImages=JSON.parse(JSON.stringify(words));
    words[0] = words[0].sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    words[1] = words[1].sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    wordsImages[0] = wordsImages[0].sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    wordsImages[1] = wordsImages[1].sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });

    letters1=[
      ...words[0],
      ...words[1]
    ];
    let images1=[
      ...wordsImages[0],
      ...wordsImages[1]
    ];
    setLetters(letters1);
    setImages(images1);
    setCount(4);
  }, [props.data]);

  useEffect(() => {
    setResponding(false)
  }, [letters]);

  function selectLetter(ind){
  }

  function drop(e){
    if(!responding){
      setResponding(true);
      if(e.dragData.i == e.dropData.i){
        correctPlay();
        let letters1=JSON.parse(JSON.stringify(letters));
        let images1=JSON.parse(JSON.stringify(images));
        letters1.map((it,i)=>{
          if(it.i==e.dragData.i){
            it.visible=false;
          }
        });
        images1.map((it,i)=>{
          if(it.i==e.dragData.i){
            it.visible=false;
          }
        });
        setLetters(letters1);
        setImages(images1);
        setCount(count+1);
        let res={
          ...e.dragData,
          correct: letters1[e.dragData.i].correct
        }
        console.log(res);
        props.response(res);
      }else{
        wrongPlay();  
        let letters1=JSON.parse(JSON.stringify(letters));        
        letters1[e.dragData.i].correct=false;                  
        setLetters(letters1);        
      }
    }
  }

  function changeInput(e){
    setImageWord({
      ...imageWord,
      [e.target.name]: e.target.value
    });
  }

  return (
    <section className="image-word">
      <article>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                visible: (i<count && it.visible)
              });
              return(
                <li key={i} className={className}>
                  <div>
                    <DragDropContainer
                      dragData={it}
                      onDrop={(e) => drop(e)}                      
                      targetKey="foo" 
                      
                      >
                      <div>
                        <p>{it.nombreEn}</p>
                      </div>
                    </DragDropContainer>
                  </div>
                </li>
              )
            })
          }
        </ul>
        <ul>
          {
            images.map((it,i)=>{
              const className = classNames({
                visible: (i<count && it.visible)
              });
              return(
                <li key={i} className={className}>
                  <div>
                    <DropTarget
                      dropData={it}
                      targetKey="foo"  
                      onDragEnter= {(e) =>{ console.log("e",e)}}                    
                      onDragLeave= {(e) =>{ console.log("e",e)}}                    
                       >
                      <figure>
                        <img src={it.image}/>
                      </figure>
                    </DropTarget>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default ImageWord;
