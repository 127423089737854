import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate} from 'react-router-dom';
import flagImage from "../assets/images/3d_Book.png";
import eyeImage from "../assets/images/eye_icon.png";

import googleImage from "../assets/images/google.png";
import fbImage from "../assets/images/facebook.png";
import mailImage from "../assets/images/mail.png";
import Swal from 'sweetalert2'

import http from "../utils/config/http";

import { useGoogleLogin } from '@react-oauth/google';
import { useDataContext } from '../DataProvider';

import { useUserContext, useUserToggleContext} from "../UserProvider";

const Login = (props) => {

  const {saveUser} = useUserToggleContext();
  const [login, setLogin] = useState({});
  const [password, setpassword] = useState("password");
  const [activelogo, setActivelogo] = useState(false);
  const [activeLetters, setActiveLetters] = useState(false);
  const [loginShow, setLoginShow] = useState(true);
  const navigate = useNavigate();
  const { local } = useDataContext();
  const user = useUserContext();

  useEffect(() => {
    console.log('LOGIN1');    
    console.log(window.cordova);
    if(window.cordova){
      // loginCall();
    }else{    
      // saveUser({"id":"436","fecha_registro":"2023-07-31 17:20:35","nombre":"Cristian Dario Malaria Valencia","token":null,"estado":"1","correo":"cdvalencia2@gmail.com","password":"123","fuenteRegistro":"facebook","premiun":"0","idioma":"1","uuid":"e203bc4bc2309a27"});
      // setTimeout(()=>{
      //   // navigate("/home");
      //   setLoginShow(true);
      // },6000)
    }
    // navigator.splashscreen.hide();
  }, []);
  
  useEffect(() => {
    console.log('LOGIN2');   
    if(!(user.id)){
      // loginCall();
    }
    // navigator.splashscreen.hide();
  }, [user]);

  async function initAdmob(){    
    admob2= await admob.start();    
  }

  function loginCall(){    
    setTimeout(()=>{
      setActivelogo(true);
    },500);
    setTimeout(()=>{
      setActiveLetters(true);
    },100);    
    // if(window.cordova){  
      // if(window.cordova.platformId=="android"){       
      //   initAdmob();
      // }
      console.log("USERR",user)      
      let data= {                
        "correo": login.correo, 
        "password": login.password
      }
      http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/login.php",data
      ).then((result) => {          
        if(result && result.data && result.data[0]){
          localStorage.setItem("user",JSON.stringify(result.data[0]));          
          navigate("/home");
          setLoginShow(true);          
          saveUser(result.data[0]);
        }else{
          Swal.fire({
            title: ((local && local["register-51"] && local["register-51"].nombre)?local["register-51"].nombre:""),          
            showConfirmButton: true,
            icon: "warning"
          }).then(()=>{
          })
        }
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
      // }else{
      //   http.get('https://www.fluxiilanguages.com/apiEnglishWeb/usuario/idiomas.php').then((result) => {
      //     let language= "es";
      //     if(window.cordova){
      //       language = Locale.language;
      //     }
      //     if((language=="zh-CN" || language=="zh-CN")){
      //       language=language.split("-")[0];
      //     }
      //     localStorage.setItem("language",language);          
      //     let ind=0;
      //     result.data.map((it)=>{
      //       if(it.idioma==language){
      //         ind=it.codigo;
      //       }            
      //     });
      //     let data={
      //       "nombre": "",    
      //       "token": "",
      //       "correo": "",
      //       "password": "",
      //       "fuenteRegistro": "",
      //       "idioma": ind,
      //       "uuid": (device && device.uuid)?device.uuid:"e203bc4bc2309a27"
      //     }     
      //     http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/addUsuario.php",data
      //     ).then((result) => {            
      //       if(result && result.data){
      //         // localStorage.setItem("user",JSON.stringify(result.data[0]));
      //         saveUser(data);                           
      //       }
      //     }).catch((err) =>
      //       console.log(err)
      //     ).finally(() =>
      //       console.log()
      //     ) 
      //   }).catch((err) =>
      //     console.log(err)
      //   ).finally(() =>
      //     console.log()
      //   ) 
      // }                            
    }

    
    // http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/login.php",login
    // ).then((result) => {
    //   console.log(result);
    //   if(result && result.data && result.data[0]){
    //     // localStorage.setItem("user",JSON.stringify(result.data[0]));
    //     saveUser(result.data[0]);
    //     navigate("/home");
    //   }else{
    //     Swal.fire({
    //       title: ((local && local["register-51"] && local["register-51"].nombre)?local["register-51"].nombre:""),          
    //       showConfirmButton: true,
    //       icon: "warning"
    //     }).then(()=>{
    //     })
    //   }
    // }).catch((err) =>
    //   console.log(err)
    // ).finally(() =>
    //   console.log()
    // )
  // }

  function loginGoogle(){
    window.plugins.googleplus.login({},
        function (obj) {          
          // alert(JSON.stringify(obj));
        },
        function (msg) {
          // console.log(msg);
          alert('error: ' + msg);
        }
    );
  }

  function loginFacebook(){
    var fbLoginSuccess = function (userData) {      
    }

    facebookConnectPlugin.login(["public_profile"], fbLoginSuccess,
      function loginError (error) {
        console.error(error)
      }
    );
  }

  function changeInput(e){
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="login">
      {!loginShow && <>
        <figure>                
        </figure>
        <div className={activelogo?"active":""}>   
          <img src={flagImage}/>             
        </div>      
        <ul className={activeLetters?"active":""}>                        
          <li>E</li>
          <li>N</li>
          <li>G</li>
          <li>L</li>
          <li>I</li>
          <li>S</li>
          <li>H</li>
        </ul>
      </>}

      {loginShow && <>
        <header>
          <figure>
            <img src={flagImage}/>
          </figure>
          <h2>{(local && local["inicio_sesion-1"] && local["inicio_sesion-1"].nombre)?local["inicio_sesion-1"].nombre:""}</h2>
        </header>
        <article>
          <div>
            <input type="text" value={login.correo} placeholder={(local && local["inicio_sesion-2"] && local["inicio_sesion-2"].nombre)?local["inicio_sesion-2"].nombre:""} name="correo" onChange={changeInput}/>
          </div>
          <div>
            <input type={password}  value={login.password} placeholder={(local && local["inicio_sesion-3"] && local["inicio_sesion-3"].nombre)?local["inicio_sesion-3"].nombre:""} name="password" onChange={changeInput}/>
            <figure onClick={()=>{setpassword((password=="text")?"password":"text")}}>
              <img src={eyeImage}/>

            </figure>
          </div>
        </article>
        <footer>
          <div class="btn" onClick={loginCall}>{(local && local["inicio_sesion-4"] && local["inicio_sesion-4"].nombre)?local["inicio_sesion-4"].nombre:""}</div>
          <div class="btn" onClick={()=>{navigate("/register");}}>{(local && local["inicio_sesion-5"] && local["inicio_sesion-5"].nombre)?local["inicio_sesion-5"].nombre:""}</div>
          {false && 
          <article>
            <p>{(local && local["inicio_sesion-6"] && local["inicio_sesion-6"].nombre)?local["inicio_sesion-6"].nombre:""}</p>
          </article>
          }
          {false && 
          <section>
            <figure onClick={()=>{loginGoogle()}}>
              <img src={googleImage}/>
            </figure>
            <figure onClick={()=>{loginFacebook()}}>
              <img src={fbImage}/>
            </figure>
            <figure>
              <img src={mailImage}/>
            </figure>
          </section>
          }
          <p onClick={()=>{navigate("/restore");}}>{(local && local["inicio_sesion-7"] && local["inicio_sesion-7"].nombre)?local["inicio_sesion-7"].nombre:""}</p>
        </footer>
      </>}
    </section>
);
}

export default Login;
