import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Route, Link, Routes, Redirect, withRouter, useLocation, useNavigate} from 'react-router-dom';

import { ReactComponent as HomeSvg } from "../../assets/images/Home_Defaultsvg.svg";
import { ReactComponent as DiscSvg } from "../../assets/images/Discount_Defaultsvg.svg";
import { ReactComponent as ProfileSvg } from "../../assets/images/Profile_Defaultsvg.svg";
import { ReactComponent as ShareSvg } from "../../assets/images/Share_Defaultsvg.svg";
import { useUserContext, useUserToggleContext} from "../../UserProvider";

import { useDataContext } from '../../DataProvider';

import http from "../../utils/config/http";


const Nav = (props) => {

  // const dispatch = useDispatch();
  const {isLogin} = useUserToggleContext();
  const [nav, setNav] = useState(props);
  const [user, setUser] = useState(JSON.parse((localStorage.getItem("user"))?localStorage.getItem("user"):"{}"));  
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const { saveLocal, local } = useDataContext();
  
  useEffect(async () => {
    document.addEventListener("backbutton", function(event){
      if(path!="/level"){
        window.history.back();
      }
    }); 
    if(!(local && local["certificado-23"])){   
      getLanguage();       
    }
    if(user && user.id && (path!="/register")){
    }else{      
      // navigate("/login");
    }
    
    
    if(window.cordova){
      console.log("window.cordova.platformId", window.cordova.platformId);      
      if(!(user.premium=="1") && (window.cordova.platformId=="android")){
        admob.configure({
          testDeviceIds: ['45ZPBQZPWGQ8MNUW'],
        });
        let adUnitId= (window.cordova.platformId=="android")?"ca-app-pub-6417094104981114/9685090467":"ca-app-pub-6417094104981114/6741631265";
        await admob
        .start()
        .then(async () =>{
          let banner = new admob.BannerAd({
            // adUnitId: 'ca-app-pub-3940256099942544/6300978111', // Test
            adUnitId,
            position: 'bottom',
          });
          
          await banner.show();
          })
          .catch(console.log)

          // const ad = new admob.NativeAd({
          //   // adUnitId: 'ca-app-pub-6417094104981114/8420373583',
          //   adUnitId: 'ca-app-pub-3940256099942544/2247696110',
          // });
          // ad.on('load', (evt) => {
            //   console.log(45564584564564);
            //   console.log(evt);
            // })
          //
          //
          // console.log(ad);
          // setTimeout(async()=>{
          //   await ad.load();
          //   await ad.show({
            //     x: 0,
            //     y: 50,
          //     width: window.screen.width,
          //     height: 300,
          //   });
          //   await ad.showWith(document.getElementById('native-ad'));
          //   document.addEventListener('admob.ad.load', async (evt) => {
          //     console.log("evt",evt);
          //     if (evt.ad instanceof admob.NativeAd) {
            //       // handle event here
            //     }
            //   });
            // },2000)        
          }

        }

      }, []);
      
  function getLanguage(){
    http.get('https://www.fluxiilanguages.com/apiEnglishWeb/usuario/idiomas.php').then((result) => {
      let language= "es";
      if(window.cordova){
        language = Locale.language;
      }
      if((language=="zh-CN" || language=="zh-CN")){
        language=language.split("-")[0];
      }
      localStorage.setItem("language",language);
      console.log(result);
      let ind=0;
      result.data.map((it)=>{
        if(it.idioma==language){
          ind=it.codigo;
        }            
      });
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/etiquetas/etiquetas.php?idioma='+ind).then((result) => {
        console.log(result);            
        saveLocal(result.data);
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )          
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  };       
    
  useEffect(() => {
    setNav(props);
    // setEditorHtml(props.editor)
  },[props.name]);

  useEffect(() => {
    props.setPath(path);
    if(!isLogin() && (path!="/login2") && (path!="/register")){
      navigate("/");//
    }
  }, [path]);

  function share(){
    // var options = {
    //   message: 'share this', // not supported on some apps (Facebook, Instagram)
    //   subject: 'the subject', // fi. for email
    //   files: ['', ''], // an array of filenames either locally or remotely
    //   url: 'https://www.website.com/foo/#bar?a=b',
    //   chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
    //   appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    //   iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    // };
    //
    // var onSuccess = function(result) {
    //   console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
    //   console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    // };
    //
    // var onError = function(msg) {
    //   console.log("Sharing failed with message: " + msg);
    // };
    //
    // window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    window.plugins.socialsharing.share('Play Store https://play.google.com/store/apps/details?id=com.appEnglish.levelA1.C1')
    // window.plugins.socialsharing.share('Play Store https://play.google.com/store/apps/details?id=com.appEnglish.levelA1.C1 App Store https://apps.apple.com/app/id1574533706 ')
  }

  function changeInput(e){
    setNav({
      ...nav,
      [e.target.name]: e.target.value
    });
  }



  return (
    <>
      {(path!="/login" && path!="/" && path!="/index.html" && path!="/level" && path!="/register" && path!="/restore") &&
      <nav>
        <ul>
          <Link to={"/home"}>
            <li className={(path=="/home" || path=="/certificado")?"active":""}>
              <HomeSvg/>
            </li>
          </Link>
          <Link to={"/compras"} >
            <li className={(path=="/compras")?"active":""}>
              <DiscSvg/>
            </li>
          </Link>
          <Link to={"/profile"}>
            <li className={(path=="/profile")?"active":""}>
              <ProfileSvg/>
            </li>
          </Link>
          <Link onClick={share}>
            <li>
              <ShareSvg/>
            </li>
          </Link>
        </ul>
      </nav>}
    </>
);
}

export default Nav;
