import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useNavigate} from 'react-router-dom';

import {Modal} from './util/modal';
import {Loading} from './util/loading';
import nivelImage from "../assets/images/medal.png";

import http from "../utils/config/http";
import backImage from "../assets/images/Back.png";
import menuImage from "../assets/images/Menu.png";
import { useUserContext, useUserToggleContext} from "../UserProvider";
import { useDataContext } from '../DataProvider';
const Certificado = (props) => {

  const [certificado, setCertificado] = useState({});
  const navigate = useNavigate();
  const [idCategoria, setIdCategoria] = useState(localStorage.getItem("idCategoria"));
  const user = useUserContext();
  const { local } = useDataContext();

  useMemo(() => {
    http.get('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/selectCertificadoIndividual.php?idNivel='+idCategoria+'&idUsuario='+user.id).then((result) => {
      console.log(result);
      if(result && result.data && result.data[0] && result.data[0].score){
        setCertificado(result.data[0]);
      }else{
        // navigate("/test/"+idCategoria);
      }
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }, []);


  function back(e){
    window.history.back();  
  }

  function changeInput(e){
    setCertificado({
      ...certificado,
      [e.target.name]: e.target.value
    });
  }

  function mejore(e){
    navigate("/test/"+idCategoria);
  }

  function inicie(e){
    navigate("/home");
  }


  return (
    <section className="certificado">
      <header>
        <figure onClick={back}>
          <img src={backImage}/>
        </figure>
        <h5>Certificado</h5>
        {false && <figure>
          <img src={menuImage}/>
        </figure>}
      </header>
      <article>
        {false && <header>
          <h4>Has been awared</h4>
          <h2>Grade {certificado.nivel}</h2>
        </header>}
          {false && <>
            <h3>In the vocabulary</h3>
            <h3>Council of Europe Level</h3>
          </>}
          <figure>
            <img src={nivelImage}/>
          </figure>
          <section>
            <header>
              <h4>{certificado.nombre}</h4>
            </header>
            <ul>
              {false && <li>
                <h6>Meaning</h6>
                <p>100%</p>
              </li>}
              {false && <li>
                <h6>Spelling</h6>
                <p>100%</p>
              </li>}
              {false && <li>
                <h6>Listening</h6>
                <p>95%</p>
              </li>}
              {false && <li>
                <h6>Use of English</h6>
                <p>20%</p>
              </li>}
            </ul>
            <footer>
              <p>{((local && local["profile-15"] && local["profile-15"].nombre)?local["profile-15"].nombre:"")} {certificado.score}%</p>
            </footer>
          </section>
      </article>
      <footer>
        <div class="btn" onClick={mejore}>
          {(local && local["certificado-23"] && local["certificado-23"].nombre)?local["certificado-23"].nombre:""}
        </div>
        <div class="btn2" onClick={inicie}>
          {(local && local["certificado-24"] && local["certificado-24"].nombre)?local["certificado-24"].nombre:""}          
        </div>
      </footer>
    </section>
);
}

export default Certificado;
