import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import main1Image from "../../assets/images/Listening.png";
import main2Image from "../../assets/images/Meaning.png";
import main3Image from "../../assets/images/Spelling.png";
import main4Image from "../../assets/images/Use of English.png";
import { useDataContext } from '../../DataProvider';


const Init = (props) => {

  const [init, setInit] = useState(null);
  const [letters, setLetters] = useState([]);
  const { local } = useDataContext();
  const [currentLevel, setCurrentLevel] = useState((localStorage.getItem("currentLevel") && JSON.parse(localStorage.getItem("currentLevel")))?JSON.parse(localStorage.getItem("currentLevel")):{});

  useMemo(() => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    for (var i = 0; i < 42; i++) {
      const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
      letters.push({
        letter: randomCharacter,
        check: false,
        correct: false
      })
    }
    letters[1].correct=true;
    setLetters(letters);
  }, []);



  function selectLetter(ind){
    console.log(letters);
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    setLetters(letters1);
  }

  function changeInput(e){
    setInit({
      ...init,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="init">
      <header>
        <h4></h4>
      </header>
      <article>
        <div>
          <figure>
            {props.typeLevelCategory== 1 && <img src={main1Image}/>}
            {props.typeLevelCategory== 2 && <img src={main2Image}/>}
            {props.typeLevelCategory== 3 && <img src={main3Image}/>}
            {props.typeLevelCategory== 4 && <img src={main4Image}/>}
          </figure>
          <p>{props.text}</p>
        </div>
        <footer>
          <button onClick={props.go} class="btn">{(currentLevel.id>=4)?((local && local["levelavanzado-82"] && local["levelavanzado-82"].nombre)?local["levelavanzado-82"].nombre:""):((local && local["level-41"] && local["level-41"].nombre)?local["level-41"].nombre:"")}</button>
        </footer>
      </article>
    </section>
);
}

export default Init;
