import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import lockImage from "../assets/images/Lock.png";
import cellImage from "../assets/images/Phone.png";
import lock2Image from "../assets/images/UnLock.png";

import { useNavigate} from 'react-router-dom';

import http from "../utils/config/http";
import { useUserContext, useUserToggleContext} from "../UserProvider";
import { useDataContext } from '../DataProvider';

const Profile = (props) => {

  const [profile, setProfile] = useState([]);
  const [count, setCount] = useState(1);
  const [notificaciones, setNotificaciones] = useState(JSON.parse(localStorage.getItem("notificaciones")));
  const navigate = useNavigate();
  const user = useUserContext();
  const {logout} = useUserToggleContext();
  const { local, checkNotificationPermissions } = useDataContext();

  useMemo(() => {
    http.get('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/selectCertificadoMasivo.php?idUsuario='+user.id).then((result) => {
      console.log(result);
      setProfile(result.data);
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
    setTimeout(()=>{
      setCount(2);
    },1000)
  }, []);
  
  useEffect(() => {
    if(count>1){
      if(notificaciones){
        checkNotificationPermissions();
      }
      localStorage.setItem("notificaciones",(notificaciones)?"true":"false");
      let data={
        "idUsuario": user.id,
        "notificacion": (notificaciones)?"1":"0"            
      }
      http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/editNotificacion.php",data
      ).then((result) => {
        console.log(result);                        
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )    
    }
  }, [notificaciones]);

  function cerrarSesion(){
    logout();
    navigate("/login");
  }

  function activeNotificaciones(){      
    setNotificaciones(!notificaciones);
  }

  

  function allApps(){      
    window.open("https://play.google.com/store/apps/dev?id=7693925840741600482", '_blank', 'location=yes');      
  }
  function five(){      
    window.open("https://play.google.com/store/apps/details?id=com.appEnglish.levelA1.C1", '_blank', 'location=yes');      
  }
  function ours(){      
    window.open("https://www.fluxiilanguages.com/", '_blank', 'location=yes');      
  }

  function share(it){
    window.plugins.socialsharing.share(it.share);
  }

  function changeInput(e){
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="profile">
      {user && user.nombre && <h2>{user.nombre}</h2>}
      {user && user.correo && <h3>{user.correo}</h3>}
      <header>
        <ul>
          {
            profile.map((it)=>{
              return(
                <li className={(it.score>=70)?"":"no-certificate"}>
                  <header>
                    <h3>{it.nombre}</h3>
                    <h3>{it.nivel}</h3>
                    {false && <h4>Usted es mejor que 89"% ":""de usuarios!</h4>}
                  </header>
                  <article>
                    <div>
                      <h5>{(local && local["profile-15"] && local["profile-15"].nombre)?local["profile-15"].nombre:""}</h5>
                      <p>{it.score}{(it.score!= null && it.score!= undefined && it.score!="")?"%":""}</p>
                    </div>
                    {false && <div>
                      <h5>las monedas obtenidas</h5>
                      <p>15340</p>
                    </div>}
                    {false &&<div>
                      <h5>Ganancias de los stickers</h5>
                      <p>5</p>
                    </div>}
                    {false &&<div>
                      <h5>temas completado</h5>
                      <p>25</p>
                    </div>}
                    {false &&<div>
                      <h5>Las palabras aprendidas</h5>
                      <p>381</p>
                    </div>}
                    <div>
                      <h5>{(local && local["profile-16"] && local["profile-16"].nombre)?local["profile-16"].nombre:""}</h5>
                      {(it.score>=70) && <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"/></svg>
                      </figure>}
                    </div>
                    <footer>
                      <div class="btn" onClick={()=>{share(it)}}>
                        <p>{(local && local["profile-17"] && local["profile-17"].nombre)?local["profile-17"].nombre:""}</p>
                        <figure>
                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/></svg>
                        </figure>
                      </div>
                    </footer>
                  </article>
                </li>
              )
          })
        }

        </ul>
      </header>
      <article>
        <h3>{(local && local["profile-18"] && local["profile-18"].nombre)?local["profile-18"].nombre:""}</h3>
        <ul>
          {
            profile.map((it)=>{
              return(
                <li onClick={()=>{if(it.score>=70){localStorage.setItem("idCategoria",it.id);navigate("/certificado")}}}>
                  <p>{it.nombre}</p>
                  <figure>
                    {(it.score>=70) && <img src={lock2Image}/>}
                    {!(it.score>=70) && <img src={lockImage}/>}
                  </figure>
                </li>
              )
          })
        }
        </ul>
      </article>
      <footer>
        <div class="btn" onClick={allApps}>
          <p>{(local && local["profile-19"] && local["profile-19"].nombre)?local["profile-19"].nombre:""}</p>
          <figure>
            <img src={cellImage}/>
          </figure>
        </div>
        <ul>          
          <li onClick={()=>{navigate("/edit-profile")}}>{(local && local["perfil-54"] && local["perfil-54"].nombre)?local["perfil-54"].nombre:""}</li>
          {false && <li onClick={()=>{navigate("/edit-password")}}>{(local && local["perfil-57"] && local["perfil-57"].nombre)?local["perfil-57"].nombre:""}</li>}
          <li><p>{(local && local["perfil-60"] && local["perfil-60"].nombre)?local["perfil-60"].nombre:""}</p> <input type="checkbox" checked={notificaciones} onChange={activeNotificaciones}/> </li>
          <li onClick={five}>{(local && local["profile-20"] && local["profile-20"].nombre)?local["profile-20"].nombre:""}</li>
          <li onClick={ours}>{(local && local["profile-21"] && local["profile-21"].nombre)?local["profile-21"].nombre:""}</li>
          <li onClick={cerrarSesion}>{(local && local["profile-22"] && local["profile-22"].nombre)?local["profile-22"].nombre:""}</li>
        </ul>
      </footer>
    </section>
);
}

export default Profile;
