import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";

const Word = (props) => {


  const [word, setWord] = useState(null);
  const [voice, setVoice] = useState("");
  const [letters, setLetters] = useState([]);
  const [letters2, setLetters2] = useState(["_","_","_","_","_","_","_","_","_"]);
  const [count, setCount] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [responding, setResponding] = useState(false);

  useMemo(() => {
    setCount(0);    
  }, [props.data]);

  useEffect(() => {
    console.log(props);
    setResponding(false);
    if(props.wrong && props.wrong.length){
      let letters1=[];
      for (var i = 0; i < 4; i++) {
        if(props.wrong[i] && props.wrong[i].nombreEn){
          letters1.push({
            letter: props.wrong[i].nombreEn,
            check: false,
            correct: false
          })
        }
      }

      letters1.splice((letters1.length+1) * Math.random() | 0, 0, {
        letter: props.data.nombreEn,
        check: false,
        correct: true
      })
      setLetters(letters1);
    }
  }, [props.wrong]);

  useEffect(() => {
    setTimeout(()=>{
      // talk();
    },300);
  }, [props.data]);

  function selectLetter(ind,letter){
    if(!responding){
      setResponding(true);
      if(count<letters2.length){
        let letters1=JSON.parse(JSON.stringify(letters2));
        letters1[count]= letter;
        setCount(count+1);
        setLetters2(letters1);

        letters1=JSON.parse(JSON.stringify(letters));
        letters1[ind].check= !(letters[ind].check);
        setLetters(letters1);
      }
      props.response(letter);
    }
  }

  function selectLetter2(ind){
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    setLetters(letters1);
  }

  function changeInput(e){
    setWord({
      ...word,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="word">
      <header>
        <div>
          <h4>{props.data.nombreEs}</h4>
        </div>
      </header>
      <article>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                done: (it.check && it.correct),
                wrong: (it.check && !it.correct)
              });
              return(
                <li key={i} className={className} onClick={()=>{selectLetter(i,it)}}>{it.letter}</li>
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default Word;
