import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate} from 'react-router-dom';

import http from "../utils/config/http";
import { useDataContext } from '../DataProvider';
import { useUserContext } from "../UserProvider";

const Nivel = (props) => {
  const user = useUserContext();

  const [nivel, setNivel] = useState({});
  const [nivelNumber, setNivelNumber] = useState(0);
  const navigate = useNavigate();
  const { local } = useDataContext();
  useMemo(() => {
    console.log(local);    
    http.get('https://www.fluxiilanguages.com/apiEnglishWeb/prueba/selectResultadoPrueba.php?idUsuario='+user.id).then((result) => {
      console.log(result);
      if(result.data[0].nivel=="A0"){
        setNivelNumber(0);
      }
      if(result.data[0].nivel=="A1"){
        setNivelNumber(1);
      }
      if(result.data[0].nivel=="A2"){
        setNivelNumber(2);
      }
      if(result.data[0].nivel=="B1"){
        setNivelNumber(3);
      }
      if(result.data[0].nivel=="B2"){
        setNivelNumber(4);
      }
      if(result.data[0].nivel=="C1"){
        setNivelNumber(5);
      }
      if(result.data[0].nivel=="C2"){
        setNivelNumber(6);
      }
      setNivel(result.data[0]);
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }, []);


  function changeInput(e){
    setNivel({
      ...nivel,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="nivel">
      <header>
        <h3>{(local && local["home-11"] && local["home-11"].nombre)?local["home-11"].nombre:""}</h3>
        <h4>{(local && local["home-12"] && local["home-12"].nombre)?local["home-12"].nombre:""}</h4>
      </header>
      <section>
        <aside>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </aside>
        <article>
          <ul>
            <li>A0</li>
            <li>A1</li>
            <li>A2</li>
            <li>B1</li>
            <li>B2</li>
            <li>C1</li>
            <li>C2</li>
          </ul>
        </article>
        <footer>
          <ul>
            <li className={(nivelNumber>=0)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=1)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=2)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=3)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=4)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=5)?"active":""}><figure></figure></li>
            <li className={(nivelNumber>=6)?"active":""}><figure></figure></li>
          </ul>
        </footer>
      </section>
      <footer>
        <button class="btn" onClick={()=>{navigate("/test")}} >{(local && local["home-13"] && local["home-13"].nombre)?local["home-13"].nombre:""}</button>
      </footer>
    </section>
);
}

export default Nivel;
