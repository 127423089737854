import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const WordMeaning = (props) => {

  const [currentLevel, setCurrentLevel] = useState((localStorage.getItem("currentLevel") && JSON.parse(localStorage.getItem("currentLevel")))?JSON.parse(localStorage.getItem("currentLevel")):{});
  const [wordMeaning, setWordMeaning] = useState(null);
  const [voice, setVoice] = useState("");
  const [letters, setLetters] = useState([]);
  const [letters2, setLetters2] = useState(["_","_","_","_","_","_","_","_","_"]);
  const [count, setCount] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [responding, setResponding] = useState(false);

  useMemo(() => {
    setCount(0);    
  }, [props.data]);

  useEffect(() => {
    console.log(props);
    setResponding(false);
    if(props.wrong && props.wrong.length){
      let letters1=[];
      for (var i = 0; i < 4; i++) {
        if(props.wrong[i] && props.wrong[i].nombreEn){
          letters1.push({
            letter: props.wrong[i].nombreEn,
            check: false,
            correct: false
          })
        }
      }

      letters1.splice((letters1.length+1) * Math.random() | 0, 0, {
        letter: props.data.nombreEn,
        check: false,
        correct: true
      })
      setLetters(letters1);
    }
  }, [props.wrong]);
  

  function selectLetter(ind,letter){
    if(!responding){
      setResponding(true);
      if(count<letters2.length){
        let letters1=JSON.parse(JSON.stringify(letters2));
        letters1[count]= letter;
        setCount(count+1);
        setLetters2(letters1);

        letters1=JSON.parse(JSON.stringify(letters));
        letters1[ind].check= !(letters[ind].check);
        setLetters(letters1);
      }
      props.response(letter);
    }
  }

  function drop(e){
    if(!responding){
      setResponding(true);
      console.log(e);      
      console.log(e.dragData);      
      
        let letters1=JSON.parse(JSON.stringify(letters2));
        letters1[count]= e.dragData;
        setCount(count+1);
        setLetters2(letters1);

        letters1=JSON.parse(JSON.stringify(letters));
        letters1[e.dragData.i].check= !(letters[e.dragData.i].check);
        setLetters(letters1);
      
      props.response(e.dragData);
    }
  }

  function selectLetter2(ind){
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    setLetters(letters1);
  }

  function changeInput(e){
    setWordMeaning({
      ...wordMeaning,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="word-meaning">
        <DropTarget
          dropData={{}}
          targetKey="foo" >
      <header>
            <div>
              <h4>{(currentLevel.id>=4)?props.data.significadoEm:props.data.significadoEs}</h4>
            </div>
      </header>
        </DropTarget>        
      <article>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                done: (it.check && it.correct),
                wrong: (it.check && !it.correct)
              });
              return(              
                <DragDropContainer
                    dragData={{...it,i}}
                    onDrop={(e) => drop(e)}
                    targetKey="foo" >

                <li key={i} className={className}>
                      {it.letter}

                </li>
                  </DragDropContainer>             
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default WordMeaning;
