import axios from 'axios';

const http = axios.create();
let cont=0;

/**
 * Interceptor for all requests
 */
http.interceptors.request.use((config) => {
    console.log(config);
    /**
     * Add your request interceptor logic here: setting headers, authorization etc.
     */
     // config.async= true;
     // config.crossDomain= true;
     // config.type= "GET";
     // config.dataType= "json";
     // config.contentType= "application/json";
     config.headers={
       'Content-Type': "application/json",
       'Accept': "application/json"
     }         
     
    //  if(config.url.indexOf("login.php")==-1 && config.url.indexOf("idiomas.php")==-1 && config.url.indexOf("addUsuario.php")==-1 && config.url.indexOf("token.php")==-1){
        cont++;     
        document.getElementById("loading").style.display = 'flex';
    //  }     
     return config;
    }, (error) => {
        
        /**
         * Add your error handlers here
        */
       
       return Promise.reject(error);
    });
    
    /**
     * Interceptor for all responces
 */
http.interceptors.response.use((response) => {

    /**
     * Add logic for successful response
    */
      
//    if(response.config.url.indexOf("login.php")==-1 && response.config.url.indexOf("idiomas.php")==-1 && response.config.url.indexOf("addUsuario.php")==-1 && response.config.url.indexOf("token.php")==-1){
        cont--;
        if(cont==0){
            document.getElementById("loading").style.display = 'none';
        }       
//    }
   return response;
}, (error) => {
    cont--;
    if(cont==0){
     document.getElementById("loading").style.display = 'none';
    }    

    /**
     * Add logic for any error from backend
     */

    return Promise.reject(error);
});

export default http;
