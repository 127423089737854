import React,{useState} from 'react';

import { BrowserRouter as Router, HashRouter, Route, Link, Routes, useLocation } from 'react-router-dom';

import Home from './pages/home';
import Certificado from './pages/certificado';
import Certificado2 from './pages/certificado2';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';
import Restore from './pages/restore';
import Compra from './pages/compra';
import Status from './pages/status';
import Nivel from './pages/nivel';
import Nivel2 from './pages/nivel2';
import Test from './pages/test';

import Main from './pages/levels/main';
import Level from './pages/levels/level';

import Template from './pages/template';
import TemplateComplete from './pages/template-complete';

import logo from './logo.svg';
import Nav from './pages/util/nav';
import './styles/App.scss';

import { UserProvider } from './UserProvider';
import { DataProvider } from './DataProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {  
  const [path, setPath] = useState("");
    
  return (
    <UserProvider>
      <DataProvider>
        <GoogleOAuthProvider clientId="<your_client_id>">
            <div className="gradient1"></div>
            <div className="gradient2"></div>
            <main className="App">
              <Router basename={"/"}>
                <Nav setPath={setPath} />
                <section className={!(path!="/login" && path!="/" && path!="/index.html" && path!="/level" && path!="/register")?"complete":""}>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/index.html" element={<Login />} />
                    <Route path="/template" element={<Template />} />
                    <Route path="/template2" element={<TemplateComplete />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/restore" element={<Restore />} />
                    <Route path="/edit-profile" element={<Register />} />
                    <Route path="/edit-password" element={<Register />} />

                    <Route path="/home" element={<Home />} />
                    <Route path="/certificado" element={<Certificado />} />
                    <Route path="/certificado2" element={<Certificado2 />} />
                    <Route path="/nivel" element={<Nivel />} />
                    <Route path="/nivel2" element={<Nivel2 />} />
                    <Route path="/nivel2/:id" element={<Nivel2 />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/test/:id" element={<Test />} />

                    <Route path="/main" element={<Main />} />
                    <Route path="/level" element={<Level />} />

                    <Route path="/status" element={<Status />} />
                    <Route path="/compras" element={<Compra />} />
                    <Route path="/compras2" element={<Compra />} />
                  </Routes>
                </section>
              </Router  >
            </main>
        </GoogleOAuthProvider>
      </DataProvider>
    </UserProvider>
  );
}

export default App;
