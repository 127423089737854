import React, {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import keySfx from '../../assets/sound/keydown.wav';
import useSound from 'use-sound';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import wrongSfx from '../../assets/sound/wrong.mp3';

const Search = (props) => {


  const [search, setSearch] = useState(null);
  const [letters, setLetters] = useState([]);
  const [anchoLetter, setAnchoLetter] = useState([]);
  const [confirmLetters, setConfirmLetters] = useState();
  const [responding, setResponding] = useState(false);
  const [touched, setTouched] = useState(false);
  const [first, setFirst] = useState(false);
  const [countWrong, setCountWrong] = useState(-1);
  const [controlCount, setControlCount] = useState(false);

  let refs = Array.from({length: 100}, a => useRef(null));

  const [
    mousePosition,
    setMousePosition
  ] = useState({ x: null, y: null });
  const [keyPlay, { stop }] = useSound(keySfx,
  {
    volume: 1,
  });
  const [wrongPlay, { stop: stopWrong }] = useSound(wrongSfx,
  {
    volume: .6,
    });
  useEffect(() => {    
    let touch=false;
    function updateStartPosition(ev){        
      setTimeout(()=>{                     
      },100)    
    }  
    function updateEndPosition(ev){        
      setTouched(false);   
      setFirst(false);  
      setControlCount(false);
    }  
    function updateMousePosition(ev){    
      setTouched(true);
      let x, y;          
      let data={        
        x:document.getElementById("puntero").getBoundingClientRect().x,
        y:document.getElementById("puntero").getBoundingClientRect().y,
        width: 16,
        height: 16,
      }     
      if (ev.touches) {
        const touch = ev.touches[0];        
        [x, y] = [touch.clientX-8, touch.clientY-8];
      } else {        
        [x, y] = [ev.clientX-8, ev.clientY-8];
      }           
      let letters_1=JSON.parse(JSON.stringify(letters));        
      
      let selected=undefined;
      // refs.map((it,i)=>{
      //   if(it.current && it.current.getBoundingClientRect){
        //     const el = it.current;
        //     const rect = el.getBoundingClientRect();         
        //     if(isCollide(data, el.getBoundingClientRect()) && touched){
          //       if(letters_1[i].check && letters_1[i].correct){
      //         selected=true;
      //       }
      //       if(letters_1[i].check && !letters_1[i].correct){
      //         selected=false;
      //       }
      //     }
      //   }
      // });

      refs.map((it,i)=>{        
        if(it.current && it.current.getBoundingClientRect){
          const el = it.current;
          const rect = el.getBoundingClientRect();         
          if(isCollide(data, el.getBoundingClientRect()) && touched && !first){            
            if(!letters_1[i].correct){
              wrongPlay();
              setFirst(true);
            }
            if(!letters_1[i].touched && !(letters_1[i].check && letters_1[i].correct)){
              keyPlay();
              letters_1[i]={
                ...letters_1[i],
                check: !letters_1[i].check,
                touched: true
              }          
              // if(selected!=undefined){
              //   if(selected==false && letters_1[i].check && letters_1[i].correct){
              //     letters_1[i].check=false;
              //   }                
              //   if(selected==true && letters_1[i].check && !letters_1[i].correct){
              //     letters_1[i].check=true;
              //   }                
              // }
            }
          }else{
            letters_1[i]={
              ...letters_1[i],              
              touched: false
            }  
          }
          
        }
      });  
      setLetters(letters_1);   
      setMousePosition({ 
        left: x, 
        top: y 
      });      
    }

    window.removeEventListener('touchstart', updateStartPosition);
    window.addEventListener('touchend', updateEndPosition);
    window.addEventListener('touchmove', updateMousePosition);
    return () => {
      window.addEventListener('touchstart', updateStartPosition);
      window.removeEventListener('touchend', updateEndPosition);
      window.removeEventListener("touchmove", updateMousePosition);
    }
  }, [letters]);
  
  useEffect(() => {    
    let letters_1=JSON.parse(JSON.stringify(letters));        
    refs.map((it,i)=>{  
      if(it.current && it.current.getBoundingClientRect){      
        letters_1[i]={
          ...letters_1[i],
          check: false,
          touched: false
        }       
      }
    });          
    setLetters(letters_1); 
    if(confirmLetters!=undefined){
      let correct=true;
      let isCorrect=false;
      letters.map((it)=>{               
        if((it.correct && !it.check) || (!it.correct && it.check)){
          correct=false;
          isCorrect=it.correct;
        }
      });              
      if(correct){        
        if(!responding){
          console.log(countWrong);          
          setResponding(true);
          let res={
            ...props.data,
            correct: (countWrong<2),
          }
          props.response(res);
        }        
      }else{
        console.log('controlCount',controlCount);        
        console.log('touched',touched);        
        if(!controlCount && touched && !isCorrect){
          setControlCount(true);
          setCountWrong(countWrong+1);
        }
      }
    } 
  }, [touched]);  

  useEffect(() => {
    if(!!window.IntersectionObserver){
      var observer = new IntersectionObserver((e)=>{
        console.log('i',e);        
      }, (e)=>{
        console.log('e',e);                
      });
    }    
  }, []);
  
  function isCollide(a, b) {
    return !(
      ((a.y + a.height) < (b.y)) ||
      (a.y > (b.y + b.height)) ||
      ((a.x + a.width) < b.x) ||
      (a.x > (b.x + b.width))
    );
  }

  useEffect(() => {
    let row=props.data.nombreEn.length+2;
    setAnchoLetter((100/(row))-4);
    setResponding(false);
    setCountWrong(-1);
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    let letters1=[];
    for (var i = 0; i < ((row)*(row)); i++) {
      const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
      letters1.push({
        letter: randomCharacter,
        check: false,
        correct: false,
        touched: false
      })
    }
    let letters2=props.data.nombreEn.split("");
    let rand = Math.floor(Math.random() * 3);
    let rand2 = Math.floor(Math.random()* (row));
    let letter=0;
    let randLetter=(Math.floor(Math.random()* 3))-1;
    if(rand==0 || rand==2){
      for (var i = 0; i < ((row)*(row)); i++) {
        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
        if(i>((row)*rand2)+randLetter && letter<letters2.length){
          letters1[i]={
            letter: letters2[letter],
            check: false,
            correct: true,
            touched: false
          }
          letter++;
        }
      }
    }
    if(rand==1){
      randLetter=(Math.floor(Math.random()* 3));
      for (var i = 0; i < ((row)*(row)); i++) {
        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
        if(i==rand2){
          for (var j = 0; j < letters2.length; j++) {
            letters1[((randLetter*row)+rand2)+(j*row)]={
              letter: letters2[j],
              check: false,
              correct: true,
              touched: false
            }
          }
        }
      }
    }
    setLetters(letters1);
    setConfirmLetters(!confirmLetters);
  }, [props.data]);

  useEffect(()=>{
    
  }, [letters]);

  function selectLetter (ind){    
    keyPlay();
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    if(!letters1[ind].correct){
      navigator.vibrate(1000);
    }
    setLetters(letters1);
    setConfirmLetters(!confirmLetters);
  }
  function handleTouchStart (ind){
    console.log(12,ind);
  }
  function handleTouchEnd (ind){
    console.log(123,ind);
  }


  function changeInput(e){
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="search">
      <header>
        <h4>{props.data.nombreEs}</h4>
      </header>
      <article>
          <div id="puntero" style={mousePosition}>
            <DragDropContainer                      
              onDrop={(e) => {}}                      
              targetKey="foo"                       
              ><p>O</p>                            
            </DragDropContainer>        
          </div>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                done: (it.check && it.correct),
                wrong: (it.check && !it.correct)
              });
              return(
                <li style={{"width": anchoLetter+"%"}} ref={refs[i]} key={i} className={className} onClick={()=>{selectLetter(i)}} onMouseEnter={()=>{console.log(11245); handleTouchStart();}} ontouchend={handleTouchEnd}>
                  <DropTarget
                        dropData={it}
                        targetKey="foo"  
                        onDragEnter= {(e) =>{ console.log("e",e)}}                    
                        onDragLeave= {(e) =>{ console.log("e",e)}}                    
                         >
                    <p>
                      {it.letter}
                    </p>
                  </DropTarget>
                </li>
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default Search;
