import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate} from 'react-router-dom';
import backImage from "../assets/images/Back.png";

import http from "../utils/config/http";
import { useDataContext } from '../DataProvider';

const Certificado = (props) => {

  const [certificado, setCertificado] = useState({});
  const navigate = useNavigate();
  const { local } = useDataContext();

  useMemo(() => {
    setCertificado(JSON.parse(localStorage.getItem("status")));
    console.log(certificado);
  }, []);


  function back(e){
    window.history.back();
  }

  function changeInput(e){
    setCertificado({
      ...certificado,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="certificado2">
      <header>
        <div onClick={back}>
          <figure>
            <img src={backImage}/>
          </figure>
        </div>
        <h5>{(local && local["home-10"] && local["home-10"].nombre)?local["home-10"].nombre:""}</h5>
      </header>
      <article>
        <header>
          <div>
            <h4>{certificado.nombre}</h4>
            <p>{certificado.nivel}</p>
          </div>
          <ul>
            <li></li>
          </ul>
        </header>
          <section>
            <p>{certificado.descripcion}</p>
          </section>
      </article>
    </section>
);
}

export default Certificado;
