import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate} from 'react-router-dom';
import flagImage from "../assets/images/3d_Book.png";
import eyeImage from "../assets/images/eye_icon.png";

import googleImage from "../assets/images/google.png";
import fbImage from "../assets/images/facebook.png";
import mailImage from "../assets/images/mail.png";
import Swal from 'sweetalert2'

import http from "../utils/config/http";
import { useUserToggleContext} from "../UserProvider";
import { useDataContext } from '../DataProvider';

const Restore = (props) => {

  const {saveUser} = useUserToggleContext();
  const [restore, setRestore] = useState({});
  const [password, setpassword] = useState("text");
  const navigate = useNavigate();
  const { local } = useDataContext();

  useMemo(() => {
  }, []);

  function restoreCall(){
    saveUser({id:1});
    navigator.vibrate(3000);
    navigate("/home");
  }


  function restoreCall(){
    let data={
      ...restore
    }
    http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/addUsuario.php",restore
    ).then((result) => {
      console.log(result);
      if(result && result.data){
        Swal.fire({
          title: ((local && local["recupera_contrasena-48"] && local["recupera_contrasena-48"].nombre)?local["recupera_contrasena-48"].nombre:""),
          showConfirmButton: true,
          icon: "warning"
        }).then(()=>{
        })
        navigate("/login");
      }else{
        Swal.fire({
          title: ((local && local["error-43"] && local["error-43"].nombre)?local["error-43"].nombre:""),
          confirmButtonText: ((local && local["error-44"] && local["error-44"].nombre)?local["error-44"].nombre:""),
          showConfirmButton: true,
          icon: "warning"
        }).then(()=>{
        })
      }
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )

  }

  function changeInput(e){
    setRestore({
      ...restore,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="restore">
      <header>        
        <figure>
          <img src={flagImage}/>
        </figure>
        <h2>{(local && local["recupera_contrase-8"] && local["recupera_contrase-8"].nombre)?local["recupera_contrase-8"].nombre:""}</h2>
      </header>
      <article>
        <div>
          <input type="text" value={restore.correo} placeholder="correo" name="correo" onChange={changeInput}/>
        </div>
      </article>
      <footer>
        <div class="btn" onClick={restoreCall}>{(local && local["recupera_contrasena-8"] && local["recupera_contrasena-8"].nombre)?local["recupera_contrasena-8"].nombre:""}</div>
        <div class="btn" onClick={()=>{navigate("/login");}}>{(local && local["inicio_sesion-66"] && local["inicio_sesion-66"].nombre)?local["inicio_sesion-66"].nombre:""}</div>
      </footer>
    </section>
);
}

export default Restore;
