import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';

import http from "../utils/config/http";


const ModalContainer = (props) => {
  const [state, setState] = useState({});
  const [login, setLogin] = useState({
    correo:"",
    password:""
  });

  useMemo(() => {
    console.log(props);
  }, []);

  const saveOnModal = (info) => {
    props.closeModal();
  }

  function changeInput(e){
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    });
  }

  return (
    <>
      <Modal closeCustomModal={props.closeModal} titleModal={props.titleModal} save={ ev => saveOnModal() } >
        <>
          <form className="modal-login">
            <div>
              <label>Correo</label>
              <input value={login.correo} name="correo" onChange={changeInput}/>
            </div>
            <div>
              <label>Password</label>
              <input value={login.password} type="password" name="password" onChange={changeInput}/>
            </div>
          </form>
          <footer>
            <div onClick={props.closeModal} className="btn footer_btns_cancel" color="success">
              <i className="iconsminds-folder-close" />
              Cancel
            </div>
            <div className="btn footer_btns_save" color="success" onClick={props.saveOnModal}>
              <i className="iconsminds-folder-add--" />
                Save
            </div>
          </footer>
        </>
      </Modal>
    </>
  );
}


const LoadingContainer = (props) => {
  const [state, setState] = useState({});
  return (
    <>
      <Loading closeCustomModal={props.closeLoading} >
        <>
        </>
      </Loading>
    </>
  );
}


const TemplateComplete = (props) => {

  // const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);

  useMemo(() => {
    http.get('https://baconipsum.com/api/?type=meat-and-filler').then((result) => {
      console.log(result);
      setTemplate(result.data[0]);
    }).catch((err) =>
      console.log(err)
    ).finally(() =>
      console.log()
    )
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  }
  const saveModal = () => {
    setOpenModal(false);
  }

  const openLoading = () => {
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },2000);
  }

  function changeInput(e){
    setTemplate({
      ...template,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="template">
      <h1>Template</h1>
      <div>
        <button className="btn" onClick={()=>{setOpenModal(true)}}>Abrir modal</button>
      </div>
      <div>
        <button className="btn" onClick={openLoading}>Loading</button>
      </div>
      <p>{template}</p>

      {!!openModal && (
        <ModalContainer closeModal={closeModal} titleModal="Login" loginInfo={openModal} save={saveModal} />
      )}

      {!!loading && (
        <LoadingContainer />
      )}

    </section>
);
}

export default TemplateComplete;
