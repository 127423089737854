import React, { useState, useContext, useMemo } from "react";

const dataContext = React.createContext();
import { useUserContext} from "./UserProvider";

import http from "./utils/config/http";

import { useNavigate } from "react-router-dom";

export function useDataContext() {
    return useContext(dataContext);
}

export function DataProvider(props) {
    
    const user = useUserContext();  
    const [local, setLocal] = useState({});    
    const [loading, setLoading] = useState(false);

    const [push, setPush] = useState({});

    const function1 = () => {
      return 1
    }

    useMemo(() => {
      setLocal((localStorage.getItem("local") && JSON.parse(localStorage.getItem("local")))?JSON.parse(localStorage.getItem("local")):{});

      if(window.cordova){  
        checkNotificationPermissions();

        // if(PushNotification && PushNotification.init){

        //   let pushContent =PushNotification.init({
        //     android: {
        //     },
        //       browser: {
        //           pushServiceURL: 'http://push.api.phonegap.com/v1/push'
        //       },
        //     ios: {
        //       alert: "true",
        //       badge: "true",
        //       sound: "true"
        //     },
        //     windows: {}
        //   });
        //   PushNotification.hasPermission(data => {
        //     console.log(data);
        //      if (data.isEnabled) {
        //        console.log('isEnabled');
        //      }else{
        //        currentComponent.init()
        //      }
        //   });
        //     pushContent.on('registration', (data) => {
        //       console.log(data);              
        //     // data.registrationId
        //     let data1={
        //       "idUsuario": user.id,    
        //       "token": data.registrationId
        //     }
        //     http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/token.php",data1
        //     ).then((result) => {
        //       console.log(result);              
        //     }).catch((err) =>
        //       console.log(err)
        //     ).finally(() =>
        //       console.log()
        //     )     
        //   });
  
        //   pushContent.on('notification', (data) => {
        //     console.log(4154654564645);            
        //     console.log('data',data);            
        //     // data.message,
        //     // data.title,
        //     // data.count,
        //     // data.sound,
        //     // data.image,
        //     // data.additionalData
        //   });
        //   pushContent.on('error', (e) => {
        //     console.log('error',e);            
        //     // e.message
        //   });
        //   console.log("pushContent",pushContent);        
        //   setPush(pushContent);
        // }   
        window.FirebasePlugin.getToken(function(token) {
          console.log('token',token);
          let data1={
            "idUsuario": user.id,    
            "token": token
          }
          http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/token.php",data1
          ).then((result) => {
            console.log(result);              
          }).catch((err) =>
            console.log(err)
          ).finally(() =>
            console.log()
          )                           
        }, function(error) {
            console.error(error);
        });
        
        window.FirebasePlugin.onTokenRefresh(function(token) {
            console.log("Actualizar para obtener nuevo token: " + token);
            let data1={
              "idUsuario": user.id,    
              "token": token
            }
            http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/token.php",data1
            ).then((result) => {
              console.log(result);              
            }).catch((err) =>
              console.log(err)
            ).finally(() =>
              console.log()
            )  
        }, function(error) {
            alert(error);
        });
        
        window.FirebasePlugin.onMessageReceived(function(message) {
          console.log('message', message);
          
            // if(message.messageType === "notification"){
            //     console.log("Notificación recibida dentro de la aplicación");
            //     if(message.tap){
            //         console.log("Presionada en: " + message.tap);
            //     }
            // }
        }, function(error) {
            console.error(error);
        }); 
      }      
    }, []);

    function checkNotificationPermissions(requested){
        console.log("check permissions");
        window.FirebasePlugin.hasPermission(function(hasPermission) {
            console.log("has permission: " + hasPermission);  
            if (hasPermission) {
                console.log("Remote notifications permission granted");
                // Granted
                window.FirebasePlugin.getToken(function(token){
                    console.log("Got FCM token: " + token)
                }, function(error) {
                    console.log("Failed to get FCM token", error);
                });
            } else if (!requested) {
                // Request permission
                console.log("Requesting remote notifications permission");
                window.FirebasePlugin.grantPermission(function(hasPermission){
                  localStorage.setItem("notificaciones",(hasPermission)?"true":"false");
                  console.log("Permission was " + (hasPermission ? "granted" : "denied"));
                });
              } else {
                // Denied
                localStorage.setItem("notificaciones",(false)?"true":"false");
                console.log("Notifications won't be shown as permission is denied");
            }
        })
        window.FirebasePlugin.onMessageReceived(function(message){
          console.log(message);        
          if(message && message.url){
              history.push(message.url)
          }
        }, function(error){
            console.log("Error on message received " + error);
        })
        
      }

    const getLocal = (local) => {
      if(localStorage.getItem("local") && JSON.parse(localStorage.getItem("local"))){
        return JSON.parse(localStorage.getItem("local"))
      }else{
        return {};        
      }      
    }

    const saveLocal = (local) => {
      localStorage.setItem("local",JSON.stringify(local));
      setLocal(local);
    }
    
    return (
        <dataContext.Provider value={{
          local,
          getLocal,
          saveLocal,
          loading,
          setLoading,                      
          push,

          checkNotificationPermissions
        }}>
          {props.children}
        </dataContext.Provider>
    );
}
