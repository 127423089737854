import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Route, Link, Routes, Redirect, withRouter, useLocation, useNavigate} from 'react-router-dom';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import fireImage from "../assets/images/3dicons.png";
import cardImage from "../assets/images/card.png";

import http from "../utils/config/http";
import { useUserContext } from "../UserProvider";
import { useDataContext } from '../DataProvider';

const Compra = (props) => {
  const user = useUserContext();

  // const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [compra, setCompra] = useState(null);
  const [products, setProducts] = useState([]);
  const [subNivel, setSubNivel] = useState(localStorage.getItem("subNivel"));
  const [typeLevelCategory, setTypeLevelCategory] = useState(localStorage.getItem("typeLevelCategory"));
  const [deviceName, setDevicedName] = useState("android");

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { local } = useDataContext();

  useMemo(() => {
    if(window.cordova){
      console.log(window.cordova.platformId);      
      setDevicedName(window.cordova.platformId);
      if((window.cordova.platformId=="android")){        
        console.log(products);
        console.log(Platform);
        const {store, ProductType, Platform} = CdvPurchase;
        if(store.products.length<1){
          document.getElementById("loading").style.display = 'flex';
        }
        console.log(store, ProductType, Platform);
        store.verbosity = store.DEBUG;
        store.register([{
          type: ProductType.CONSUMABLE,
          id: 'sin_anuncios',
          platform: Platform.GOOGLE_PLAY
        }]);
        store.when()
        .productUpdated(refreshUI)
        .approved(finishPurchase);
        if(!store.initializedHasBeenCalled){
          store.initialize([Platform.GOOGLE_PLAY]);
        }
        setProducts(store.products);
      }
      if(window.cordova.platformId=="ios"){
        // anual
        // mensual
        // comprarTodo
        console.log(products);
        const {store, ProductType, Platform} = CdvPurchase;
        if(store.products.length<1){
          document.getElementById("loading").style.display = 'flex';
        }
        console.log(store, ProductType, Platform);
        store.verbosity = store.DEBUG;
        store.register([{
          type: ProductType.CONSUMABLE,
          id: 'comprarTodo',
          platform: Platform.APPLE_APPSTORE
        }]);
        store.when()
        .productUpdated(finishPurchaseIos)
        .approved(finishPurchase);
        if(!store.initializedHasBeenCalled){
          store.initialize([Platform.APPLE_APPSTORE]);
        }
        setProducts(store.products);
      }
    }
  }, []);

  function finishPurchaseIos(p) {
    localStorage.goldCoins = (localStorage.goldCoins | 0) + 10;
    p.finish();
    refreshUIIos();
  }
  function refreshUIIos() {
    document.getElementById("loading").style.display = 'none';
    const {store, ProductType, Platform} = CdvPurchase;
    let myProduct = store.get('comprarTodo', Platform.GOOGLE_PLAY);
    setProducts([
      ...products,
      myProduct
    ]);
    console.log(myProduct);

    // const button = `<button onclick="myProduct.getOffer().order()">Purchase</button>`;
    // console.log("myProduct",myProduct);
    // document.getElementsByTagName('body')[0].innerHTML = `
    // <div>
    //   <pre>
    //     Gold: ${localStorage.goldCoins | 0}
    //
    //     Product.state: ${myTransaction ? myTransaction.state : ''}
    //            .title: ${myProduct ? myProduct.title : ''}
    //            .descr: ${myProduct ? myProduct.description : ''}
    //            .price: ${myProduct ? myProduct.pricing.price : ''}
    //
    //   </pre>
    //   ${myProduct.canPurchase ? button : ''}
    // </div>`;
  }
  function finishPurchase(p) {
    localStorage.goldCoins = (localStorage.goldCoins | 0) + 10;
    p.finish();
    refreshUI();
  }

  function refreshUI() {
    document.getElementById("loading").style.display = 'none';
    const {store, ProductType, Platform} = CdvPurchase;
    let myProduct = store.get('sin_anuncios', Platform.GOOGLE_PLAY);
    setProducts([
      ...products,
      myProduct
    ]);
    console.log(myProduct);

    // const button = `<button onclick="myProduct.getOffer().order()">Purchase</button>`;
    // console.log("myProduct",myProduct);
    // document.getElementsByTagName('body')[0].innerHTML = `
    // <div>
    //   <pre>
    //     Gold: ${localStorage.goldCoins | 0}
    //
    //     Product.state: ${myTransaction ? myTransaction.state : ''}
    //            .title: ${myProduct ? myProduct.title : ''}
    //            .descr: ${myProduct ? myProduct.description : ''}
    //            .price: ${myProduct ? myProduct.pricing.price : ''}
    //
    //   </pre>
    //   ${myProduct.canPurchase ? button : ''}
    // </div>`;
  }

  async function video(){
    if(window.cordova){
      if(window.cordova.platformId=="android"){
        let adUnitId= (window.cordova.platformId=="android")?"ca-app-pub-6417094104981114/3867395802":"ca-app-pub-6417094104981114/4113552205";
        let rewarded = new admob.RewardedAd({
          // adUnitId: 'ca-app-pub-3940256099942544/5224354917', // Test
          adUnitId,
        })
    
        rewarded.on('load',async (evt) => {
          console.log(evt);
        });
        let ctrl=0;
        document.getElementById("loading").style.display = 'flex';;
    
        document.addEventListener('admob.rewarded.reward', async (e) => {
          console.log("reward");
          console.log(e);
          if(ctrl<1){
            ctrl++;
            http.post("https://www.fluxiilanguages.com/apiEnglishWeb/nivel/addResultado.php",{
              "idUsuario": user.id,
              "idSubNivel": subNivel,
              "idCategoria": typeLevelCategory,
              "totalPuntos": 0,
              "estado": 0,
              "estadoVisible": 1
            }
            ).then((result) => {
              navigate("/main");
              if(result){
              }
            }).catch((err) =>
              console.log(err)
            ).finally(() =>
              console.log()
            )
          }
        })
        document.addEventListener('admob.rewarded.dismiss', async (e) => {
          document.getElementById("loading").style.display = 'none';;
        })
    
        console.log(rewarded);
    
        await rewarded.load()
        await rewarded.show()
      }      
    }
  }



  function back(){
    window.history.back();
  }

  function comprar(){
    const {store} = CdvPurchase;
    console.log(products);
    const myTransaction = store.findInLocalReceipts(products[0]);
    console.log(myTransaction);
    products[0].getOffer().order();

    store.when(product.id)
    .approved(function(p) {
        console.log("Store: approved", p);
        p.verify();
        http.post("https://www.fluxiilanguages.com/apiEnglishWeb/usuario/purchase.php",{
          "idUsuario": user.id,
          "uuid": user.uuid
        }
        ).then((result) => {    
          navigate("/login");
          if(result){
          }
        }).catch((err) =>
          console.log(err)
        ).finally(() =>
          console.log()
        )
    });
  }


  function changeInput(e){
    setCompra({
      ...compra,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="compra">
      {
        (path=="/compras") && deviceName=="android" &&
          <header>
            <figure>
              <img src={fireImage}/>
            </figure>
            <p>{(local && local["compras-64"] && local["compras-64"].nombre)?local["compras-64"].nombre:""}</p>
          </header>
      }
      {
        (path=="/compras2") &&
          <header>
            <figure className="back" onClick={back}>
              <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
            </figure>
            {(deviceName=="android") &&  
              <>
                <h4>{(local && local["compras2-29"] && local["compras2-29"].nombre)?local["compras2-29"].nombre:""}</h4>             
                <div className="btn" onClick={video}>
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg>
                  </figure>
                  <p>{(local && local["compras2-30"] && local["compras2-30"].nombre)?local["compras2-30"].nombre:""}</p>
                </div>
                
                <h5>{(local && local["compras2-31"] && local["compras2-31"].nombre)?local["compras2-31"].nombre:""}</h5>
              </>
            }
          </header>
      }
      <div>        
        <article>
          <header>
            <h4>{(local && local["compras-83"] && local["compras-83"].nombre)?local["compras-83"].nombre:""}</h4>
          </header>
          <section>
            <article>
              {products.length>0 && <h3>{products[0].pricing.price}</h3>}
              <p>{(local && local["compras-84"] && local["compras-84"].nombre)?local["compras-84"].nombre:""}</p>
            </article>
          </section>
          <footer>
            {products.length>0 && <div class="btn" onClick={comprar}>
              {(local && local["compras-14"] && local["compras-14"].nombre)?local["compras-14"].nombre:""}
              <figure>
                <img src={cardImage}/>
              </figure>
            </div>}
            {false && <p>Restaurar compra anterior</p>}
          </footer>
        </article>        
      </div>
    </section>
);
}

export default Compra;
