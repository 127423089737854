import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from './util/modal';
import {Loading} from './util/loading';
import { useNavigate, useLocation } from 'react-router-dom';
import nivelImage from "../assets/images/medal.png";

import http from "../utils/config/http";

import { useUserContext } from "../UserProvider";
import { useDataContext } from '../DataProvider';

const Nivel2 = (props) => {

  const [nivel2, setNivel2] = useState({});
  const { local } = useDataContext();
  const user = useUserContext();
  const [currentLevel, setCurrentLevel] = useState((localStorage.getItem("currentLevel") && JSON.parse(localStorage.getItem("currentLevel")))?JSON.parse(localStorage.getItem("currentLevel")):{});
  const location = useLocation();
  const [url, setUrl] = useState(location.pathname.split("/"));

  const navigate = useNavigate();
  useMemo(() => {
    if(url && url[2]){
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/selectCertificadoIndividual.php?idUsuario='+user.id+'&idNivel='+currentLevel.id).then((result) => {
        console.log(result);
        setNivel2(result.data[0]);
      }).catch((err) =>
        console.log(err)
        ).finally(() =>
        console.log()
        )
    }else{
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/prueba/selectResultadoPrueba.php?idUsuario='+user.id).then((result) => {
        console.log(result);
        setNivel2(result.data[0]);
      }).catch((err) =>
      console.log(err)
      ).finally(() =>
        console.log()
      )      
    }
  }, []);
  
  
  function reset(){
    if(url && url[2]){
      navigate("/test/"+currentLevel.id);
    }else{
      navigate("/test");
    }
  }

  function changeInput(e){
    setNivel2({
      ...nivel2,
      [e.target.name]: e.target.value
    });
  }


  return (
    <section className="nivel2">
      <header>
        <h3>{((local && local["home-71"] && local["home-71"].nombre)?local["home-71"].nombre:"")}</h3>
        <h4>{nivel2.nivel} - {nivel2.nombre}</h4>
      </header>
      <section>
        <article>
          <figure>
            <img src={nivelImage}/>
          </figure>
        </article>
        <h2>{(nivel2.score)?((local && local["profile-15"] && local["profile-15"].nombre)?local["profile-15"].nombre:""):""} {(nivel2.score)?(nivel2.score+"%"):""}</h2>
      </section>
      <footer>
        <button class="btn" onClick={()=>{navigate("/home")}} >{(local && local["certificado-24"] && local["certificado-24"].nombre)?local["certificado-24"].nombre:""}</button>
        <button class="btn2" onClick={reset} >{((local && local["home-72"] && local["home-72"].nombre)?local["home-72"].nombre:"")}</button>
      </footer>
    </section>
);
}

export default Nivel2;
