import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';

import {Modal} from './util/modal';
import {Loading} from './util/loading';
import backImage from "../assets/images/Back.png";
import menuImage from "../assets/images/Menu.png";
import http from "../utils/config/http";

import Swal from 'sweetalert2';
import { useDataContext } from '../DataProvider';

const ModalContainer = (props) => {
  const [state, setState] = useState({});
  const [words, setWords] = useState([]);
  const [login, setLogin] = useState({
    correo:"",
    password:""
  });
  const { local } = useDataContext();

  useEffect(() => {
    props.word.buena.correct=true;
    let word=[
      ...props.word.malas,
      props.word.buena
    ];
    console.log(word);
    const shuffeled = word.sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    setWords(shuffeled);
  }, [props.word]);

  const selectWord = (info) => {
    props.selectWordModal(info)
  }

  function changeInput(e){
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    });
  }

  return (
    <>
      <Modal closeCustomModal={props.closeModal} save={ ev => saveOnModal() } >
          <section className="modal-test">
            <header>
              {false && <h3>Examinese</h3>}
              <h4>{props.word.buena.nombreEn}</h4>
            </header>
            <ul>
              {
                words.map((it)=>{
                  return(
                    <li onClick={()=>{selectWord(it)}}>
                      <figure>
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero"/></svg>
                      </figure>
                      <p>{it.nombreEs}</p>
                    </li>
                  )
              })
            }
          </ul>
        </section>
      </Modal>
    </>
  );
}

const Test = (props) => {

  const [test, setTest] = useState([]);
  const [currentWord, setCurrentWord] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(0);
  
  const [user, setUser] = useState(JSON.parse((localStorage.getItem("user"))?localStorage.getItem("user"):"{}"));
  const navigate = useNavigate();
  const location = useLocation();
  const [url, setUrl] = useState(location.pathname.split("/"));
  const { local } = useDataContext();

  useMemo(() => {

    console.log(location);    
    console.log(url);
    if(url && url[2]){
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/selectEvaluacion.php?nivel='+url[2]).then((result) => {
        console.log(result);
        setTest(result.data);
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }else{
      http.get('https://www.fluxiilanguages.com/apiEnglishWeb/prueba/selectPrueba.php?idUsuario='+user.id).then((result) => {
        console.log(result);
        setTest(result.data);
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }
  }, []);

  function back(e){
    // window.history.back(); 
    navigate("/home") 
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  function selectWord(word,i){
    if(word.correct==undefined){
      if(count>1){
        setCurrentWord(i);
        setOpenModal(true);
        setCount(0);
      }else{
        test[i].correct=true;
        setCount(count+1);
      }
    }
  }

  function selectWordModal(word){
    let test1=JSON.parse(JSON.stringify(test));
    if(word.correct){
      test[currentWord].correct=true;
    }else{
      test[currentWord].correct=false;
    }
    console.log(test);
    setTest(test);
    setOpenModal(false);
  }

  function sendTest(e){
    let ganadas=0;
    let perdidas=0;
    test.map((it)=>{
      if(it.correct){
        ganadas++;
      }else{
        perdidas++
      }
    })    
    console.log(url);
    console.log((url && url[2]));
    if(url && url[2]){
      let data={
        idUsuario: user.id,
        idNivel: url[2],
        ganadas,
        perdidas
      };
      http.post('https://www.fluxiilanguages.com/apiEnglishWeb/evaluacion/addEvaluacion.php',data).then((result) => {
        console.log(result);
        Swal.fire({
          title: ((local && local["home-70"] && local["home-70"].nombre)?local["home-70"].nombre:""),
          icon: "success"
        }).then(()=>{
          navigate("/nivel2/"+url[2])
        })
      }).catch((err) =>
        console.log(err)
      ).finally(() =>
        console.log()
      )
    }else{
      let data={
        "idUsuario": user.id,
        ganadas,
        perdidas
      };
      http.post('https://www.fluxiilanguages.com/apiEnglishWeb/prueba/addPrueba.php',data).then((result) => {
        console.log(result);
        Swal.fire({
          title: ((local && local["home-70"] && local["home-70"].nombre)?local["home-70"].nombre:""),
          icon: "success"
        }).then(()=>{
          navigate("/nivel2");
        })
      }).catch((err) =>{
        console.log(err)
      }).finally(() =>
        console.log()
      )
    }
  }

  function changeInput(e){
    setTest({
      ...test,
      [e.target.name]: e.target.value
    });
  }

  
  return (    
    <section className="test">
      <header>
        <figure onClick={back}>
          <img src={backImage}/>
        </figure>
        {url && url[2] && <h5>{((local && local["home-67"] && local["home-67"].nombre)?local["home-67"].nombre:"")}</h5>}
        {!(url && url[2]) && <h5>{((local && local["home-9"] && local["home-9"].nombre)?local["home-9"].nombre:"")}</h5>}
        {false && <figure>
          <img src={menuImage}/>
        </figure>}
      </header>
      <article>
        <header>
          <figure>
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-8c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z" fill-rule="nonzero"/></svg>
          </figure>
          <p>{((local && local["home-69"] && local["home-69"].nombre)?local["home-69"].nombre:"")}</p>
        </header>
        <section>
          <ul>
            {
              test.map((it,i)=>{
                return(
                  <li className={((it.correct)?"active":"")+((it.correct==false)?"fail":"")} onClick={()=>{selectWord(it,i)}}>
                    <figure>
                      <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero"/></svg>
                    </figure>
                    <p>{it.buena.nombreEn}</p>
                  </li>
                )
            })
          }
          </ul>
          {test.length>0 && <footer>
            <button class="btn" onClick={sendTest} >{((local && local["home-53"] && local["home-53"].nombre)?local["home-53"].nombre:"")}</button>
          </footer>}
        </section>
      </article>
      {!!openModal && (
        <ModalContainer closeModal={closeModal} selectWordModal={selectWordModal} word={test[currentWord]} />
      )}
    </section>
);
}

export default Test;
