import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Modal} from '../util/modal';
import {Loading} from '../util/loading';
import classNames from "classnames";
import http from "../../utils/config/http";
import keySfx from '../../assets/sound/keydown.wav';
import useSound from 'use-sound';
import wrongSfx from '../../assets/sound/wrong.mp3';
import correctSfx from '../../assets/sound/correct.mp3';

const Audio2 = (props) => {


  const [audio2, setAudio2] = useState(null);
  const [voice, setVoice] = useState("");
  const [letters, setLetters] = useState([]);
  const [letters2, setLetters2] = useState(["_","_","_","_","_","_","_","_","_"]);
  const [count, setCount] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [responding, setResponding] = useState(false);
  const [lettersCorrect, setLettersCorrect] = useState([]);
  const [countWrong, setCountWrong] = useState(0);

const [keyPlay, { stop }] = useSound(keySfx,
  {
    volume: 1,
  });
  const [wrongPlay, { stop: stopWrong }] = useSound(wrongSfx,
    {
      volume: .6,
     });
  const [correctPlay, { sound }] = useSound(correctSfx,
  {
    volume: 1,
    onend: (e) => {
      // console.log(e);
      // console.log(sound);
      // sound.playing(0);
    },
  });
  useMemo(() => {
    setCount(0);

    if(!window.cordova){
      window.speechSynthesis.onvoiceschanged = function(e) {
        loadVoices();
      };
    }
    console.log('props',props);
    
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    let letters2=props.data.nombreEn.split("");
    let pos=props.data.significadoEn.toLowerCase().indexOf(props.data.nombreEn.toLowerCase());

    let letters3=[];
    let letters1=[];
    let letters4=[];
    letters2.map((it,i)=>{
      letters3.push("_");
      letters4.push(it);

      letters1.push({
        letter: it,
        check: false,
        correct: true,
        visible: true,
        i
      })
    })
    setLettersCorrect(letters4);

    const shuffeled = letters1.sort(() => {
      const randomTrueOrFalse = Math.random() > 0.5;
      return randomTrueOrFalse ? 1 : -1
    });
    setCountWrong(0);

    setCount(0);
    setLetters2(letters3);
    setLetters(shuffeled);
  }, [props.data]);

  useEffect(() => {
  }, [props.wrong]);

  useEffect(() => {
    setTimeout(()=>{
      talk();
    },300);
  }, [props.data]);

  function loadVoices() {
  	var voices = speechSynthesis.getVoices();
    console.log(voices);
    let found=false;
    voices.map((it)=>{
      if(it.lang=="en-US" && !found){
        found=it;
      }
    })
    setVoice(found);
  }

  function talk(){
    if(props.data && props.data.nombreEn){
      setPlaying(true);
      setTimeout(()=>{
        setPlaying(false);
      },props.data.nombreEn.length*120);
      let text= props.data.nombreEn;
      console.log(text);
      if(window.cordova){
        TTS.speak({
          text: text,
          locale: 'en-US',
          rate: 1
        }, function () {
          console.log('Text succesfully spoken');
        }, function (reason) {
          console.log(reason);
        });
      }else{
        let msg = new SpeechSynthesisUtterance();

        msg.text = text;

        msg.volume = parseFloat(20);
        msg.rate = parseFloat(1);
        msg.pitch = parseFloat(10);

        if (voice) {
        	msg.voice = speechSynthesis.getVoices().filter(function(voice) { return voice.name == voice; })[0];
        }
        window.speechSynthesis.speak(msg);
      }
    }
  }

  function selectLetter(ind,it){
    if(lettersCorrect[count].toUpperCase()==it.letter.toUpperCase()){
      keyPlay();
      if(count<letters2.length){
        let letters1=JSON.parse(JSON.stringify(letters2));
        letters1[count]= it.letter;
        setCount(count+1);
        setLetters2(letters1);

        letters1=JSON.parse(JSON.stringify(letters));
        letters1[ind].check= !(letters[ind].check);
        // letters1[ind].visible= false;
        setLetters(letters1);
        console.log(count,letters2.length);
        if(count==letters2.length-1){
          correctPlay();
          let res={
            ...props.data,
            correct: (countWrong<2)

          }
          props.response(res);
        }
      }
    }else{
      setCountWrong(countWrong+1);

      wrongPlay();
    }
  }

  function selectLetter2(ind){
    let letters1=JSON.parse(JSON.stringify(letters));
    letters1[ind].check= !(letters[ind].check);
    setLetters(letters1);
  }

  function changeInput(e){
    setAudio2({
      ...audio2,
      [e.target.name]: e.target.value
    });
  }




  return (
    <section className="audio2">
      <header>
        <div onClick={talk}>
          <figure>{playing}
            <ul className={(playing)?"animate":""}>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </figure>
        </div>
        <article>
          <h4>
          {
            letters2.map((it,i)=>{
              return(
                <span key={i}>{it}</span>
              )
            })
          }
          </h4>
        </article>
      </header>
      <article>
        <ul>
          {
            letters.map((it,i)=>{
              const className = classNames({
                done: (it.check && it.correct),
                wrong: (it.check && !it.correct)
              });
              return(
                <>
                  {(!it.check) && <li key={i} className={className} onClick={()=>{selectLetter(i,it)}}>{it.letter}</li>}
                </>
              )
            })
          }
        </ul>
      </article>
    </section>
);
}

export default Audio2;
